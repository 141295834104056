import React, { useState, useEffect } from "react";
import { BenefitsItemWrap } from "../styles/common/benefitsItem.styled";
import benefitsIcon01 from "../assets/images/benefit-icon-01.png";
import benefitsIcon02 from "../assets/images/benefit-icon-02.png";
import benefitsIcon03 from "../assets/images/benefit-icon-03.png";
import benefitsIcon04 from "../assets/images/benefit-icon-04.png";
import benefitsIcon05 from "../assets/images/benefit-icon-05.png";

const BenefitItem = ({ icon, title, details }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isSingleColumn, setIsSingleColumn] = useState(window.innerWidth <= 1600);

  useEffect(() => {
    const handleResize = () => {
      setIsSingleColumn(window.innerWidth <= 1600);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <li
      className="benefit-item"
      style={{
        width: isSingleColumn ? "100%" : "calc(50% - 20px)",
        maxWidth: isSingleColumn ? "none" : "540px",
        minWidth: isSingleColumn ? "none" : "460px",
      }}
    >
      <div className="benefit-detail" onClick={() => setIsOpen(!isOpen)}>
        <div className="benefit-info">
          <div className="benefit-icon">
            <img src={icon} className="benefit-icon-item" alt="" />
          </div>
          <div className="benefit-name">
            <p className="benefit-name-txt">{title}</p>
          </div>
        </div>
        <div className="benefit-arrow">
          <svg
            className="pc_only"
            xmlns="http://www.w3.org/2000/svg"
            style={{
              transform: isOpen ? "rotate(180deg)" : "rotate(0deg)",
              transformOrigin: "center",
              transition: "all 0.3s",
            }}
            width="24"
            height="15"
            viewBox="0 0 24 15"
            fill="none"
          >
            <path d="M2 2L12 12L22 2" stroke="white" strokeWidth="4" strokeLinecap="round" />
          </svg>
          <svg
            className="pc_none"
            style={{
              transform: isOpen ? "rotate(180deg)" : "rotate(0deg)",
              transformOrigin: "center",
              transition: "all 0.3s",
            }}
            width="14"
            height="9"
            viewBox="0 0 14 9"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M1 1L7 7L13 1" stroke="black" strokeWidth="2" strokeLinecap="round" />
          </svg>
        </div>
      </div>
      {isOpen && (
        <div className="benefit-grown">
          <ul className="benefit-grown-list">
            {details.map((item, index) => (
              <li key={index} className="benefit-grown-item">
                {item}
              </li>
            ))}
          </ul>
        </div>
      )}
    </li>
  );
};

const BenefitsItem = () => {
  const benefitData = [
    {
      icon: benefitsIcon01,
      title: "성과는 일하기 좋은 환경에서!",
      details: [
        "하루 7시간 근무",
        "시차출근제 (9:00 ~ 10:30 자율 출근)",
        "자기계발비 매월 15만원 지원",
        "최상급 업무 장비 제공",
        "무제한 스낵바 운영",
      ],
    },
    {
      icon: benefitsIcon02,
      title: "당신은 소중한 사람!",
      details: [
        "점심·저녁 식사비 지원",
        "연 1회 건강검진 무료 제공(20만원 상당)",
        "생일 반차 및 선물 지급",
        "명절선물, 경조휴가, 경조사비 지급",
        "야근 택시비 지원",
      ],
    },
    {
      icon: benefitsIcon03,
      title: "보상과 휴식은 확실하게!",
      details: [
        "6개월 단위 연봉 협상 진행",
        "성과에 따른 인센티브 / 스톡옥션 부여 기회 제공",
        "야근 진행시, 연장근로수당 지급",
        "휴가비 지원 (근로자 휴가지원사업)",
      ],
    },
    {
      icon: benefitsIcon04,
      title: "건강한 팀문화로 즐겁게!",
      details: [
        "팀단합을 위한 분기별 팀활동비 지원!",
        "매월 신규 입사자 / 생일자 축하 정기 티타임 진행",
        "회사 비전, 목표를 CEO가 직접 공유하는 타운홀 미팅 진행",
      ],
    },
    {
      icon: benefitsIcon05,
      title: "직접 만든 서비스, 맘껏 누리세요!",
      details: [
        "자사 서비스 무료 및 할인 제공",
        "자사 보유 렌탈스튜디오 공간 무료 대여",
        "계열사 운영 자녀 코딩 교육 무료 제공",
      ],
    },
  ];

  return (
    <BenefitsItemWrap>
      {benefitData.map((item, index) => (
        <BenefitItem key={index} icon={item.icon} title={item.title} details={item.details} />
      ))}
    </BenefitsItemWrap>
  );
};

export default BenefitsItem;
