import React from "react";
import { IndexWrap } from "./Index.styled";
import Header from "../../components/employment/header/Header";

function Index({ children, isLoggedIn, setIsLoggedIn, userName }) {
  return (
    <IndexWrap>
      <Header isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} userName={userName} />
      <div>{children}</div>
    </IndexWrap>
  );
}

export default Index;
