import styled from "@emotion/styled";

export const MoreBtnWrap = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3px 0;
    background-color: transparent;
    outline: none;
    border: none;
    border-bottom: 3px solid #fff;
    color: #fff;
    font-family: "Pretendard";
    font-size: 18px;
    font-weight: 700;
    cursor: pointer;
`;
