import "./App.css";
import GlobalStyle from "./styles/globalStyle";
import "./styles/globals.css";
import Header from "./components/header";
import Main from "./pages/main";
import Footer from "./components/footer";
import "./styles/font.css";
import { Routes, Route } from "react-router-dom";
import Admin from "./admin/pages/main/Main";

function App() {
  return (
    <>
      <GlobalStyle />
      <div className="App">
        <Routes>
          {/* 일반 사용자용 경로 */}
          <Route
            path="/*"
            element={
              <>
                <Header />
                <Main />
                <Footer />
              </>
            }
          />

          {/* 관리자용 경로 */}
          <Route path="/admin/*" element={<Admin />} />
        </Routes>
      </div>
    </>
  );
}

export default App;
