import React, { useState, useEffect, useMemo } from "react";
import Table from "../../components/employment/table/Table";
import { EmploymentWrap } from "./Employment.styled";
import Tabs from "../../components/employment/tabs/Tabs";
import Sidebar from "../../components/employment/sidebar/Sidebar";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getAllRecruitments,
  getAllApplicants,
  getAllDocumentReviews,
  getAllfirstInterview,
  getAllsecondInterview,
  getAllFinalAcceptances,
} from "../../api/auth";

function Employment() {
  const location = useLocation();
  const navigate = useNavigate();

  const queryParams = new URLSearchParams(location.search);
  const initialCategory = queryParams.get("id");
  const initialTopCategory = queryParams.get("topCategory");
  const initialTab = queryParams.get("tab") || "applicantList";
  const initialName = queryParams.get("name") || ""; // name 파라미터 기본값 설정

  const [activeTab, setActiveTab] = useState(initialTab);
  const [selectedCategory, setSelectedCategory] = useState(initialCategory);
  const [topCategory, setTopCategory] = useState(initialTopCategory);
  const [openTopCategories, setOpenTopCategories] = useState(
    initialTopCategory ? [initialTopCategory] : [],
  );

  const [searchName, setSearchName] = useState(initialName);

  const [recruitments, setRecruitments] = useState([]);
  const [applicants, setApplicants] = useState([]);
  const [documentReviews, setDocumentReviews] = useState([]);
  const [firstInterviews, setFirstInterviews] = useState([]);
  const [secondInterviews, setSecondInterviews] = useState([]);
  const [finalAcceptances, setFinalAcceptances] = useState([]);

  const [recruitmentResults, setRecruitmentResults] = useState(0);
  const [applicantResults, setApplicantResults] = useState(0);
  const [documentResults, setDocumentResults] = useState(0);
  const [firstInterviewResults, setFirstInterviewResults] = useState(0);
  const [secondInterviewResults, setSecondInterviewResults] = useState(0);
  const [finalAcceptanceResults, setFinalAcceptanceResults] = useState(0);

  const [recruitmentLoading, setRecruitmentLoading] = useState(false);
  const [applicantLoading, setApplicantLoading] = useState(false);

  // 에러 상태 관리 추가
  const [error, setError] = useState(null);

  // 페이지네이션 상태 추가
  const [page, setPage] = useState(1); // 초기 페이지 값 설정
  const [pageSize, setPageSize] = useState(20); // 페이지 크기 기본값
  const [totalResults, setTotalResults] = useState(0);
  const [totalPagesDisplay, setTotalPagesDisplay] = useState(1);

  const tabs = useMemo(
    () => [
      { key: "jobPostings", label: "공고" },
      { key: "applicantList", label: "지원자 리스트" },
      { key: "documentEvaluation", label: "서류 평가" },
      { key: "firstInterview", label: "1차 면접" },
      { key: "secondInterview", label: "2차 면접" },
      { key: "finalAcceptance", label: "최종 입사자" },
    ],
    [],
  );

  // 최종적으로 totalResults를 기반으로 totalPagesDisplay 계산
  useEffect(() => {
    let results = 0;

    if (activeTab === "jobPostings") {
      results = recruitmentResults;
    } else if (activeTab === "applicantList") {
      results = applicantResults;
    } else if (activeTab === "documentEvaluation") {
      results = documentResults;
    } else if (activeTab === "firstInterview") {
      results = firstInterviewResults;
    } else if (activeTab === "secondInterview") {
      results = secondInterviewResults;
    } else if (activeTab === "finalAcceptance") {
      results = secondInterviewResults;
    }

    setTotalResults(results);
    setTotalPagesDisplay(Math.ceil(results / pageSize) || 1);
  }, [
    recruitmentResults,
    applicantResults,
    documentResults,
    firstInterviewResults,
    secondInterviewResults,
    finalAcceptanceResults,
    activeTab,
    pageSize,
  ]);

  // 공고 목록 불러오기
  const fetchRecruitments = async () => {
    setRecruitmentLoading(true);
    setError(null); // 에러 초기화
    try {
      const data = await getAllRecruitments(page, pageSize); // 공고탭 테이블 데이터
      setRecruitments(Array.isArray(data.results) ? data.results : []);
      setRecruitmentResults(data.count || 0); // 공고 totalResults 관리
    } catch (error) {
      console.error("공고 데이터를 불러오는 중 오류가 발생했습니다:", error);
      setError(error.response.data.error);
    } finally {
      setRecruitmentLoading(false);
    }
  };

  // 사이드바 데이터를 위한 공고 목록 호출
  const fetchSidebarRecruitments = async () => {
    try {
      const data = await getAllRecruitments(1, 100); // 사이드바 공고 데이터
      setRecruitments(Array.isArray(data.results) ? data.results : []);
    } catch (error) {
      console.error("사이드바 공고 데이터를 불러오는 중 오류가 발생했습니다:", error);
    }
  };

  // 처음 화면이 로드될 때와 공고 탭이 활성화될 때 호출
  useEffect(() => {
    if (activeTab === "jobPostings") {
      fetchRecruitments(); // 공고 탭의 테이블 데이터 호출
    }
    fetchSidebarRecruitments(); // 항상 사이드바 데이터를 호출
  }, [activeTab, page, pageSize]);

  // 공고 탭으로 이동할 때 이외 탭의 데이터를 초기화
  useEffect(() => {
    setPage(1);

    if (activeTab === "jobPostings") {
      // 다른 탭의 데이터 초기화
      setApplicants([]);
      setDocumentReviews([]);
      setFirstInterviews([]);
      setSecondInterviews([]);
      setFinalAcceptances([]);
    }
  }, [activeTab]);

  // 공고 탭 이외의 데이터를 불러오기
  useEffect(() => {
    const fetchData = async () => {
      setApplicantLoading(true);
      setError(null); // 에러 초기화
      try {
        let data = {};
        if (activeTab === "applicantList") {
          data = await getAllApplicants(selectedCategory || null, page, pageSize, searchName);
          setApplicants(Array.isArray(data.results) ? data.results : []);
          setApplicantResults(data.count || 0); // 지원자 totalResults 관리
        } else if (activeTab === "documentEvaluation") {
          data = await getAllDocumentReviews(selectedCategory || null, page, pageSize, searchName);
          setDocumentReviews(Array.isArray(data.results) ? data.results : []);
          setDocumentResults(data.count || 0); // 서류 평가 totalResults 관리
        } else if (activeTab === "firstInterview") {
          data = await getAllfirstInterview(selectedCategory || null, page, pageSize, searchName);
          setFirstInterviews(Array.isArray(data.results) ? data.results : []);
          setFirstInterviewResults(data.count || 0); // 1차 면접 totalResults 관리
        } else if (activeTab === "secondInterview") {
          data = await getAllsecondInterview(selectedCategory || null, page, pageSize, searchName);
          setSecondInterviews(Array.isArray(data.results) ? data.results : []);
          setSecondInterviewResults(data.count || 0); // 2차 면접 totalResults 관리
        } else if (activeTab === "finalAcceptance") {
          data = await getAllFinalAcceptances(selectedCategory || null, page, pageSize, searchName);
          setFinalAcceptances(Array.isArray(data.results) ? data.results : []);
          setFinalAcceptanceResults(data.count || 0); // 최종 입사자 totalResults 관리
        }
      } catch (error) {
        console.error(`${activeTab} 데이터를 불러오는 중 오류가 발생했습니다:`, error);
        setError(error.response?.data?.error || "데이터를 불러오는 중 문제가 발생했습니다.");
      } finally {
        setApplicantLoading(false);
      }
    };

    if (activeTab !== "jobPostings") {
      fetchData();
    }
  }, [activeTab, selectedCategory, page, pageSize, searchName]);

  // 모든 로딩 상태를 확인 후 totalPagesDisplay를 계산
  useEffect(() => {
    if (!recruitmentLoading && !applicantLoading) {
      setTotalPagesDisplay(Math.ceil(totalResults / pageSize) || 1);
    }
  }, [recruitmentLoading, applicantLoading, totalResults, pageSize]);

  const groupedRecruitments = useMemo(() => {
    const groups = {};
    recruitments.forEach((item) => {
      const title = item.title || "기타";
      if (!groups[title]) groups[title] = [];
      groups[title].push(item);
    });
    return groups;
  }, [recruitments]);

  const categories =
    activeTab === "jobPostings" ? { "공고 목록": recruitments } : groupedRecruitments;

  // URL 파라미터를 업데이트하는 함수
  const updateUrlParams = (topCategory, category, newName) => {
    const params = new URLSearchParams(location.search);
    params.set("tab", activeTab);

    if (activeTab !== "jobPostings") {
      if (topCategory) params.set("topCategory", topCategory);
      if (category) params.set("id", category);
      if (newName) {
        params.set("name", newName); // name 파라미터 추가
      } else {
        params.delete("name"); // 빈 값일 경우 name 파라미터 제거
      }
    } else {
      params.delete("topCategory");
      params.delete("id");
      params.delete("name");
    }

    navigate(`?${params.toString()}`, { replace: true });
  };

  // 컴포넌트 로드 시 초기 검색어를 상태에 반영
  useEffect(() => {
    if (initialName) {
      setSearchName(initialName); // URL에서 검색어 가져와 설정
    }
  }, [initialName]);

  // 탭 변경 시 URL 파라미터 및 카테고리 상태 관리
  useEffect(() => {
    if (activeTab === "jobPostings") {
      setSelectedCategory(null);
      setTopCategory(null);
      setOpenTopCategories([]);
      setSearchName("");
    }
    updateUrlParams(topCategory, selectedCategory, searchName);
  }, [activeTab, topCategory, selectedCategory, searchName]);

  // Sidebar에서 선택한 카테고리를 업데이트하고 URL에 반영하는 함수
  const handleCategorySelect = (topCategory, categoryId) => {
    setTopCategory(topCategory);
    setSelectedCategory(categoryId);
    setPage(1);
    setSearchName(""); // 검색어 상태 초기화
    updateUrlParams(topCategory, categoryId, ""); // name 파라미터 제거

    if (activeTab === "jobPostings") {
      fetchRecruitments(true); // 사이드바에서 호출할 때는 고정된 page와 pageSize를 사용
    }
  };

  // 전체보기 클릭 시 selectedCategory를 null로 강제로 업데이트
  const resetCategory = () => {
    setTopCategory(null);
    setSelectedCategory(null);
    setSearchName("");
    setPage(1); // 페이지 초기화
  };

  // 페이지네이션 변경 함수
  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handlePageSizeChange = (newPageSize) => {
    setPageSize(newPageSize);
    setPage(1); // 페이지 크기 변경 시 첫 페이지로 이동
  };

  // 검색 핸들러
  const handleSearch = (newSearchName) => {
    const trimmedName = newSearchName.trim();
    setSearchName(trimmedName); // 상태 업데이트
    setPage(1); // 검색 시 페이지 초기화

    // 빈 값이면 name 파라미터 제거, 아니면 추가
    updateUrlParams(topCategory, selectedCategory, trimmedName);

    console.log("검색어:", trimmedName || "빈 값");
  };

  const getTableData = () => {
    // 로딩 중일 때의 데이터 반환
    if (applicantLoading) {
      return {
        title: "로딩 중...",
        data: [],
        columns: [],
        page,
        pageSize,
        totalResults,
        onPageChange: handlePageChange,
        onPageSizeChange: handlePageSizeChange,
        totalPagesDisplay: Math.ceil(totalResults / pageSize) || 1, // 계산된 페이지수 유지
        loading: true, // 로딩 상태를 전달
      };
    }

    // 로딩이 아닐 때의 데이터 반환
    let rowData = [];
    let columns = [];
    let title = "";

    if (activeTab === "jobPostings") {
      rowData = recruitments;
      title = "공고 목록";
      columns = [
        { field: "title", headerName: "공고 제목" },
        { field: "registration_date", headerName: "채용 시작일" },
        { field: "deadline_date", headerName: "채용 마감일" },
        {
          field: "announcement_link",
          headerName: "공고 링크",
          cellRenderer: (props) => (
            <a href={props.value} target="_blank" rel="noopener noreferrer">
              링크 보기
            </a>
          ),
        },
        {
          field: "teams",
          headerName: "담당 팀",
        },
        {
          field: "is_closed",
          headerName: "마감 여부",
          valueFormatter: (params) => (params.value ? "마감" : "진행 중"),
        },
        // { field: "created_at", headerName: "생성일" },
      ];
    } else if (activeTab === "applicantList") {
      rowData = applicants;
      title = "지원자 리스트";
      columns = [
        { field: "name", headerName: "이름" },
        { field: "email", headerName: "이메일" },
        { field: "phone_number", headerName: "연락처" },
        {
          field: "resume_url",
          headerName: "이력서",
          cellRenderer: (props) => (
            <a href={props.value} target="_blank" rel="noopener noreferrer">
              이력서 보기
            </a>
          ),
        },
        {
          field: "portfolio_url",
          headerName: "포트폴리오",
          cellRenderer: (props) =>
            props.value ? (
              <a href={props.value} target="_blank" rel="noopener noreferrer">
                포트폴리오 보기
              </a>
            ) : (
              "없음"
            ),
        },
        { field: "recruitment.title", headerName: "채용 직무" },
        { field: "status", headerName: "상태" },
        { field: "send_message", headerName: "이메일/문자 전송" },
      ];
    } else if (activeTab === "documentEvaluation") {
      rowData = documentReviews;
      title = "서류 평가";
      columns = [
        { field: "applicant.name", headerName: "이름" },
        { field: "applicant.email", headerName: "이메일" },
        { field: "applicant.phone_number", headerName: "연락처" },
        {
          field: "applicant.resume_url",
          headerName: "이력서",
          cellRenderer: (props) => (
            <a href={props.value} target="_blank" rel="noopener noreferrer">
              이력서 보기
            </a>
          ),
        },
        {
          field: "applicant.portfolio_url",
          headerName: "포트폴리오",
          cellRenderer: (props) =>
            props.value ? (
              <a href={props.value} target="_blank" rel="noopener noreferrer">
                포트폴리오 보기
              </a>
            ) : (
              "없음"
            ),
        },
        { field: "status", headerName: "서류 합격 여부" },
      ];
    } else if (activeTab === "firstInterview") {
      rowData = firstInterviews;
      title = "1차 면접";
      columns = [
        { field: "applicant.name", headerName: "이름" },
        { field: "applicant.email", headerName: "이메일" },
        { field: "applicant.phone_number", headerName: "연락처" },
        {
          field: "applicant.resume_url",
          headerName: "이력서",
          cellRenderer: (props) => (
            <a href={props.value} target="_blank" rel="noopener noreferrer">
              이력서 보기
            </a>
          ),
        },
        {
          field: "applicant.portfolio_url",
          headerName: "포트폴리오",
          cellRenderer: (props) =>
            props.value ? (
              <a href={props.value} target="_blank" rel="noopener noreferrer">
                포트폴리오 보기
              </a>
            ) : (
              "없음"
            ),
        },
        { field: "schedule_status", headerName: "면접 일정 조율" },
        { field: "schedule.scheduled_date", headerName: "1차 면접 일정" },
        { field: "evaluation_form_url", headerName: "면접 평가표" },
        { field: "status", headerName: "1차 합격 여부" },
      ];
    } else if (activeTab === "secondInterview") {
      rowData = secondInterviews;
      title = "2차 면접";
      columns = [
        { field: "applicant.name", headerName: "이름" },
        { field: "applicant.email", headerName: "이메일" },
        { field: "applicant.phone_number", headerName: "연락처" },
        {
          field: "applicant.resume_url",
          headerName: "이력서",
          cellRenderer: (props) => (
            <a href={props.value} target="_blank" rel="noopener noreferrer">
              이력서 보기
            </a>
          ),
        },
        {
          field: "applicant.portfolio_url",
          headerName: "포트폴리오",
          cellRenderer: (props) =>
            props.value ? (
              <a href={props.value} target="_blank" rel="noopener noreferrer">
                포트폴리오 보기
              </a>
            ) : (
              "없음"
            ),
        },
        { field: "schedule_status", headerName: "면접 일정 조율" },
        { field: "schedule.scheduled_date", headerName: "2차 면접 일정" },
        { field: "evaluation_form_url", headerName: "면접 평가표" },
        { field: "status", headerName: "2차 합격 여부" },
      ];
    } else if (activeTab === "finalAcceptance") {
      rowData = finalAcceptances;
      title = "최종 입사자";
      columns = [
        { field: "applicant.name", headerName: "이름" },
        { field: "applicant.email", headerName: "이메일" },
        { field: "applicant.phone_number", headerName: "연락처" },
        {
          field: "applicant.resume_url",
          headerName: "이력서",
          cellRenderer: (props) => (
            <a href={props.value} target="_blank" rel="noopener noreferrer">
              이력서 보기
            </a>
          ),
        },
        {
          field: "applicant.portfolio_url",
          headerName: "포트폴리오",
          cellRenderer: (props) =>
            props.value ? (
              <a href={props.value} target="_blank" rel="noopener noreferrer">
                포트폴리오 보기
              </a>
            ) : (
              "없음"
            ),
        },
        { field: "applicant.recruitment.title", headerName: "채용 직무" },
        { field: "joining_date", headerName: "최종 입사일" },
        { field: "salary", headerName: "연봉 정보" },
      ];
    }

    return {
      title,
      data: rowData,
      columns,
      page,
      pageSize,
      totalResults,
      onPageChange: handlePageChange,
      onPageSizeChange: handlePageSizeChange,
      totalPagesDisplay: Math.ceil(totalResults / pageSize) || 1, // 계산된 페이지수 유지
      loading: applicantLoading,
      error,
    };
  };

  return (
    <EmploymentWrap>
      <Tabs activeTab={activeTab} setActiveTab={setActiveTab} tabs={tabs} />
      <div className="con-container">
        <Sidebar
          categories={categories}
          selectedCategory={selectedCategory}
          setSelectedCategory={handleCategorySelect}
          openTopCategories={openTopCategories}
          setOpenTopCategories={setOpenTopCategories}
          activeTab={activeTab}
          resetCategory={resetCategory}
        />
        <Table
          tableData={getTableData()}
          activeTab={activeTab}
          selectedCategory={selectedCategory}
          onSearch={handleSearch}
          searchName={searchName}
          resetSearch={() => setSearchName("")}
        />
      </div>
    </EmploymentWrap>
  );
}

export default Employment;
