import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import TimeScheduleModal from "../modal/TimeScheduleModal";
import SendMessageModal from "../modal/SendMessageModal";
import MultiSelector from "../multiSelector/multiSelector";
import { TableWrap, StyledCheckbox } from "./Table.styled";
import useDateFormatter from "../../../hooks/useDateFormatter";

import {
  createRecruitment,
  createApplicant,
  updateApplicant,
  updateDocumentReview,
  updateFirstInterview,
  updateSecondInterview,
  updateRecruitment,
  updateFinalAcceptance,
  deleteApplicant,
  deleteRecruitment,
  deleteDocumentReview,
  deleteFirstInterview,
  deleteSecondInterview,
  deleteFinalAcceptance,
} from "../../../api/auth";

// 일정 조율 버튼 렌더러
const ScheduleButtonRenderer = ({ props, authority, activeTab }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    if (activeTab === "firstInterview" && authority === "owner") {
      // 1차 면접 일정 조율은 팀리드, 어드민만 참여 (대표님 x)
      alert("1차 면접 일정은 팀리드만 참여할 수 있습니다.");
      return;
    }

    if (props.data.schedule_status === "확정" && authority !== "admin") {
      // 관리자 외에는 확정 상태에서 변경 불가
      // 확정 상태일 경우 alert 표시
      alert("확정 상태일 때는 일정 변경이 불가합니다.\n관리자에게 문의해주세요.");
      return;
    }
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const getButtonLabel = (authority, scheduleStatus) => {
    const labels = {
      admin: {
        미정: "일정 조율중",
        대기중: "일정 선택",
        확정: "일정 변경",
      },
      team_lead: {
        미정: "일정 선택",
        대기중: "일정 재선택",
        확정: "일정 확정",
      },
      owner: {
        미정: "일정 선택",
        대기중: "일정 재선택",
        확정: "일정 확정",
      },
    };

    return labels[authority]?.[scheduleStatus] || "일정 조율";
  };

  // 사용
  const renderButtonLabel = () => getButtonLabel(authority, props.data.schedule_status);
  console.log("조율 props", props);

  return (
    <>
      <button className="schedule-button" onClick={handleOpenModal}>
        {renderButtonLabel()}
      </button>
      <TimeScheduleModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        data={props.data}
        authority={authority}
        activeTab={activeTab}
      />
    </>
  );
};

// 문자/이메일 전송 버튼 렌더러
const SendMessageButtonRenderer = ({ props, authority }) => {
  const [isModalOpen, setIsSModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsSModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsSModalOpen(false);
  };

  console.log("조율 props", props);

  return (
    <>
      <button className="schedule-button" onClick={handleOpenModal}>
        템플릿 열기
      </button>
      <SendMessageModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        data={props.data}
        authority={authority}
      />
    </>
  );
};

// 평가표 보기 링크 렌더러
const EvaluationLinkRenderer = (props) => {
  if (props.value) {
    return (
      <a href={props.value} target="_blank" rel="noopener noreferrer">
        평가표 보기
      </a>
    );
  }
  return null;
};

// 체크박스 렌더러 (마감 여부 필드용)
const CheckboxRenderer = (props) => {
  const handleCheckboxChange = async (event) => {
    const newValue = event.target.checked;
    props.setValue(newValue);

    const updateData = { is_closed: newValue };

    try {
      await updateRecruitment(props.data.id, updateData);
      console.log("마감 여부 업데이트 성공:", updateData);
    } catch (error) {
      console.error("마감 여부 업데이트 실패:", error);
    }
  };

  return (
    <StyledCheckbox
      type="checkbox"
      checked={props.value}
      onChange={handleCheckboxChange}
      aria-label="마감 여부"
    />
  );
};

// 채용 공고 링크 렌더러
const JobLinkRenderer = (props) => {
  if (props.value) {
    return (
      <a
        href={props.value}
        target="_blank"
        rel="noopener noreferrer"
        style={{
          textDecoration: "none",
          color: "inherit",
        }}
      >
        {props.value}
      </a>
    );
  }
  return null;
};

// 포트폴리오 링크 렌더러
const PofolLinkRenderer = (props) => {
  const portfolioUrl = props.data?.applicant?.portfolio_url || props.value || "";

  if (portfolioUrl) {
    return (
      <a
        href={portfolioUrl}
        target="_blank"
        rel="noopener noreferrer"
        style={{
          textDecoration: "none",
          color: "inherit",
        }}
      >
        {portfolioUrl}
      </a>
    );
  }
  return null;
};

const MultiLineEditor = (props) => {
  const handleChange = (event) => {
    props.stopEditing(); // 셀 편집 종료
    props.api.setValue(event.target.value); // 값 업데이트
  };

  return (
    <textarea
      defaultValue={props.value}
      onBlur={(event) => handleChange(event)}
      style={{ width: "100%", height: "100%" }}
    />
  );
};

function Table({ tableData, activeTab, selectedCategory, onSearch, searchName, resetSearch }) {
  const [rowData, setRowData] = useState([]);
  const [authority, setAuthority] = useState(null);
  const [searchInput, setSearchInput] = useState(""); // 입력 필드의 값

  const location = useLocation(); // 현재 URL 가져오기
  const queryParams = new URLSearchParams(location.search); // URLSearchParams로 파라미터 추출
  const recruitmentId = queryParams.get("id"); // 'id' 파라미터 값 추출

  const {
    data,
    columns,
    page = 1,
    pageSize = 20,
    totalResults = 0,
    onPageChange,
    onPageSizeChange,
    totalPagesDisplay = 1,
    loading = false,
    error,
  } = tableData || {};

  const { formatDate, formatDateTime } = useDateFormatter();

  // if (!tableData || !tableData.columns) {
  //   return <div>데이터가 없습니다.</div>;
  // }

  useEffect(() => {
    // localStorage에서 authority 값을 가져와 상태에 저장
    const storedAuthority = localStorage.getItem("authority");
    setAuthority(storedAuthority);
    console.log("Authority", storedAuthority);
  }, []); // 컴포넌트가 처음 렌더링될 때 한 번만 실행

  // tableData.data 변경 시 rowData 동기화
  useEffect(() => {
    const updatedData = tableData.data.map((item) => ({
      ...item,
      teams: item.teams ? item.teams.map((team) => team.name) : [], // name 필드만 추출
      selected: item.selected || false,
    }));
    setRowData(updatedData);
  }, [tableData.data]);

  // activeTab이 jobPostings일 때 검색어 초기화
  useEffect(() => {
    if (activeTab === "jobPostings") {
      setSearchInput(""); // activeTab이 jobPostings일 때 검색어 초기화
    }
  }, [activeTab]);

  // 부모가 전달한 resetSearch 호출 시 검색어 초기화
  useEffect(() => {
    if (!searchName) {
      setSearchInput(""); // 검색 입력 초기화
    }
  }, [searchName]);

  // useEffect(() => {
  //   setSearchInput(""); // 검색어 초기화
  // }, [selectedCategory]);

  // 빈 행 추가 함수
  const addEmptyRow = () => {
    if (activeTab === "applicantList" && !recruitmentId) {
      // recruitmentId가 없는 경우 alert 표시
      alert("좌측 사이드바에서 특정 공고 선택 후 지원자를 추가 할 수 있습니다.");
      return;
    }

    const emptyRow =
      activeTab === "jobPostings"
        ? {
            id: null,
            title: "",
            registration_date: "",
            deadline_date: "",
            announcement_link: "",
            is_closed: "",
            selected: false,
          }
        : {
            id: null,
            recruitment: recruitmentId,
            name: "",
            email: "",
            phone_number: "",
            resume_url: "",
            portfolio_url: "",
            status: "",
            applicant: {},
            selected: false,
          };

    setRowData((prevRowData) => [...prevRowData, emptyRow]);
  };

  const handleCellUpdate = async (params) => {
    console.log("handleCellUpdate called"); // 호출 여부 확인
    console.log("Params:", params);

    // params와 params.data의 유효성 확인
    if (!params?.data) {
      console.error("Invalid params or data is undefined:", params);
      return;
    }

    const updatedField = params?.colDef?.field; // colDef 안전 접근
    const updatedValue = params?.newValue;

    const rowData = params.data;
    console.log("Row Data:", rowData);

    if (!updatedField) {
      console.error("Field is undefined in params.colDef:", params);
      return;
    }

    let id;

    // 각 탭에 맞는 ID 설정
    if (activeTab === "applicantList") {
      id = rowData.id;
    } else if (activeTab === "jobPostings") {
      id = rowData.id; // 공고 탭에서는 recruitment ID 사용
    } else {
      id = rowData.applicant?.id; // 안전 접근
    }

    console.log("Update Target ID:", id);
    console.log("Update Data:", { [updatedField]: updatedValue });

    // 업데이트할 데이터 객체 생성
    const updateData = {
      [updatedField]:
        updatedField === "teams" && Array.isArray(updatedValue)
          ? updatedValue // 이미 배열 형식이면 그대로 사용
          : updatedValue,
    };

    try {
      if (!id) {
        console.log("ID가 없으므로 새로운 데이터를 생성합니다.");
        // POST 요청 조건: id가 없고, 공고의 title 또는 지원자의 name이 입력된 경우
        if (activeTab === "jobPostings" && updatedField === "title" && updatedValue) {
          const response = await createRecruitment({ title: updatedValue });
          rowData.id = response.id; // 새로 생성된 공고 ID 반영
          console.log("공고 생성 성공:", response);

          // rowData를 업데이트
          setRowData((prevRowData) =>
            prevRowData.map((row) =>
              row === params.data ? { ...params.data, id: response.id } : row,
            ),
          );
        } else if (activeTab === "applicantList" && updatedField === "name" && updatedValue) {
          const newApplicantData = { recruitment: recruitmentId, name: updatedValue };
          const response = await createApplicant(newApplicantData);
          rowData.id = response.id; // 새로 생성된 지원자 ID 반영
          console.log("지원자 생성 성공:", response);

          // rowData를 업데이트
          setRowData((prevRowData) =>
            prevRowData.map((row) =>
              row === params.data ? { ...params.data, id: response.id } : row,
            ),
          );
        } else {
          console.error("필수 데이터가 없어 POST 요청을 실행하지 않았습니다.");
          return;
        }
      } else {
        // PUT 요청: 기존 데이터 업데이트
        if (activeTab === "applicantList") {
          await updateApplicant(id, updateData);
        } else if (activeTab === "documentEvaluation") {
          await updateDocumentReview(id, updateData);
        } else if (activeTab === "firstInterview") {
          await updateFirstInterview(id, updateData);
        } else if (activeTab === "secondInterview") {
          await updateSecondInterview(id, updateData);
        } else if (activeTab === "finalAcceptance") {
          await updateFinalAcceptance(id, updateData);
        } else if (activeTab === "jobPostings") {
          await updateRecruitment(id, updateData);
        }
        console.log("업데이트 성공:", updateData);
      }
    } catch (error) {
      console.error("업데이트 실패:", error);
    }

    // rowData 업데이트
    setRowData((prevRowData) =>
      prevRowData.map((row) => (row.id === id ? { ...row, [updatedField]: updatedValue } : row)),
    );
  };

  // 선택된 행 삭제 함수
  const deleteSelectedRows = async () => {
    const selectedRows = rowData.filter((row) => row.selected);

    for (const row of selectedRows) {
      try {
        if (activeTab === "applicantList") {
          await deleteApplicant(row.id);
        } else if (activeTab === "jobPostings") {
          await deleteRecruitment(row.id);
        } else if (activeTab === "documentEvaluation") {
          await deleteDocumentReview(row.id);
        } else if (activeTab === "firstInterview") {
          await deleteFirstInterview(row.id);
        } else if (activeTab === "secondInterview") {
          await deleteSecondInterview(row.id);
        } else if (activeTab === "finalAcceptance") {
          await deleteFinalAcceptance(row.id);
        }

        console.log(`ID ${row.id} 삭제 성공`);
      } catch (error) {
        console.error(`ID ${row.id} 삭제 실패:, error`);
      }
    }

    setRowData((prevRowData) => prevRowData.filter((row) => !row.selected));
  };

  // 입력 필드 변경 핸들러
  const handleInputChange = (e) => {
    setSearchInput(e.target.value); // 입력 필드 값만 업데이트
  };

  const gridColumns = [
    {
      headerName: "-",
      field: "selected",
      autoHeight: false,
      sortable: false,
      cellRendererFramework: (params) => (
        <input
          type="checkbox"
          checked={params.data.selected || false}
          onChange={(e) => {
            const isSelected = e.target.checked;
            const updatedRowData = rowData.map((row) =>
              row.id === params.data.id ? { ...row, selected: isSelected } : row,
            );
            setRowData(updatedRowData);
          }}
        />
      ),
      width: 50,
    },
    ...tableData.columns.map((col) => {
      const nonEditableFields = [
        "applicant.name",
        "applicant.email",
        "applicant.phone_number",
        "applicant.resume_url",
        "applicant.portfolio_url",
      ];

      // 편집 불가능한 필드 처리
      if (nonEditableFields.includes(col.field)) {
        return {
          headerName: col.headerName,
          field: col.field,
          editable: false, // 편집 비활성화
          valueGetter: (params) => {
            const fieldPath = col.field.split(".");
            let value = params.data;
            fieldPath.forEach((key) => {
              value = value ? value[key] : "";
            });
            return value || ""; // 값 반환
          },
        };
      }

      if (col.field === "schedule_status") {
        return {
          headerName: col.headerName,
          field: col.field,
          cellRenderer: (params) => (
            <ScheduleButtonRenderer
              props={params}
              authority={authority} // authority를 props로 전달
              activeTab={activeTab}
            />
          ),
          editable: false,
        };
      } else if (col.field === "title") {
        return {
          headerName: col.headerName,
          field: col.field,
          width: 180,
          cellClass: "hoverable-cell", // 클래스 추가
          onCellClicked: (params) => {
            if (params.value) {
              const url = `/admin?tab=applicantList&topCategory=${encodeURIComponent(
                params.value,
              )}&id=${params.data.id}`;

              window.location.href = url; // 클릭 시 URL로 이동
            }
          },
          cellRenderer: (params) => {
            return params.value || null; // 텍스트로만 렌더링
          },
        };
      } else if (col.field === "is_closed") {
        return {
          headerName: col.headerName,
          field: col.field,
          width: 120,
          cellRendererFramework: CheckboxRenderer,
        };
      } else if (col.field === "send_message") {
        return {
          headerName: col.headerName,
          field: col.field,
          editable: false,
          cellRenderer: (params) => (
            <SendMessageButtonRenderer props={params} authority={authority} />
          ),
        };
      } else if (col.field === "registration_date") {
        return {
          headerName: col.headerName,
          field: col.field,
          valueGetter: (params) => {
            // ISO 형식을 YYYY-MM-DD 형식으로 변환
            return formatDate(params.data.registration_date);
          },
          valueSetter: (params) => {
            const editedValue = params.newValue; // 사용자가 입력한 값
            // YYYY-MM-DD 형식을 ISO 형식으로 변환
            const date = new Date(editedValue);
            if (!isNaN(date)) {
              params.data.registration_date = date.toISOString(); // ISO 형식으로 저장
              return true; // 값이 업데이트됨
            }
            return false; // 값이 유효하지 않을 경우 업데이트하지 않음
          },
          valueFormatter: (params) => formatDate(params.value), // 포맷된 값 표시
        };
      } else if (col.field === "evaluation_form_url") {
        return {
          headerName: col.headerName,
          field: col.field,
          cellRenderer: EvaluationLinkRenderer,
          valueGetter: (params) => params.data.evaluation_form_url || "",
        };
      } else if (col.field === "portfolio_url" || col.field === "applicant.portfolio_url") {
        return {
          headerName: col.headerName,
          field: activeTab === "applicantList" ? "portfolio_url" : "applicant.portfolio_url",
          cellRenderer: PofolLinkRenderer,
          valueGetter: (params) => {
            return activeTab === "applicantList"
              ? params.data.portfolio_url
              : params.data.applicant?.portfolio_url || "";
          },
        };
      } else if (col.field === "link" || col.field === "announcement_link") {
        return {
          headerName: col.headerName,
          field: col.field,
          width: 210,
          cellRenderer: JobLinkRenderer,
          valueGetter: (params) => {
            return typeof params.data[col.field] === "string" ? params.data[col.field] : "";
          },
        };
      } else if (col.field === "resume_url" || col.field === "applicant.resume_url") {
        return {
          headerName: col.headerName,
          field: activeTab === "applicantList" ? "resume_url" : "applicant.resume_url",
          cellRenderer: PofolLinkRenderer,
          valueGetter: (params) => {
            return activeTab === "applicantList"
              ? params.data.resume_url
              : params.data.applicant?.resume_url || "";
          },
        };
      } else if (col.field === "teams") {
        return {
          headerName: col.headerName,
          field: col.field,
          width: 210,
          cellEditor: MultiSelector,
          cellEditorParams: {
            options: [
              "운영총괄팀",
              "교육1팀",
              "교육2팀",
              "교육3팀",
              "교육4팀",
              "교육5팀",
              "마케팅팀",
              "crm팀",
              "개발팀",
              "경영지원팀",
              "학원팀",
              "와쳐팀",
              "한국프차팀",
            ],
            onValueChanged: (params) => {
              console.log("MultiSelector onValueChanged params:", params);

              const { newValue, rowId, rowData } = params; // 전달받은 값
              console.log("Updated Teams:", newValue);
              console.log("Row ID:", rowId);

              if (!rowId) {
                console.error("Row ID not found");
                return;
              }

              // 서버로 전달할 데이터 형식 변환
              const formattedTeams = newValue.map((team) => team.name);
              console.log("Formatted Teams for API:", formattedTeams);

              // handleCellUpdate 호출
              handleCellUpdate({
                data: { ...rowData, teams: formattedTeams }, // 수정된 행 데이터
                colDef: { field: "teams" },
                newValue: formattedTeams,
              });
            },
          },
          editable: true,
          autoHeight: true,
          valueFormatter: (params) => (Array.isArray(params.value) ? params.value.join(", ") : ""),
          valueGetter: (params) => {
            return Array.isArray(params.data?.teams) ? params.data.teams : [];
          },
          valueSetter: (params) => {
            if (Array.isArray(params.newValue)) {
              params.data.teams = params.newValue;
              return true;
            }
            return false;
          },
        };
      } else if (col.field === "status" && activeTab === "applicantList") {
        return {
          headerName: col.headerName,
          field: col.field,
          editable: false, // 상태 필드는 수정 불가
          cellRenderer: (params) => params.value || "", // 상태값 표시
          cellStyle: { cursor: "pointer" },
          cellClass: "hoverable-cell",
          onCellClicked: (params) => {
            const status = params.value;
            const { recruitment } = params.data;

            if (!recruitment || !recruitment.title || !recruitment.id) {
              console.error("유효한 recruitment 데이터가 없습니다.");
              return;
            }

            const topCategory = recruitment.title;
            const id = recruitment.id;

            // 상태값에 따라 이동할 탭 설정
            let targetTab = "";
            if (["서류 평가 미처리", "서류 평가 불합격"].includes(status)) {
              targetTab = "documentEvaluation"; // 서류탭
            } else if (status === "서류 평가 합격") {
              targetTab = "firstInterview"; // 1차 면접 탭
            } else if (["1차 면접 대기 중", "1차 면접 불합격"].includes(status)) {
              targetTab = "firstInterview"; // 1차 면접 탭
            } else if (status === "1차 면접 합격") {
              targetTab = "secondInterview"; // 2차 면접 탭
            } else if (["2차 면접 대기 중", "2차 면접 불합격"].includes(status)) {
              targetTab = "secondInterview"; // 2차 면접 탭
            } else if (status === "2차 면접 합격") {
              targetTab = "finalAcceptance"; // 최종 입사자 탭
            } else {
              console.error("유효하지 않은 상태값:", status);
              return;
            }

            // 이동 로직
            const url = `/admin?tab=${targetTab}&topCategory=${encodeURIComponent(
              topCategory,
            )}&id=${id}`;
            window.location.href = url; // 브라우저에서 새 URL로 이동
          },
        };
      } else if (col.field === "status" && activeTab !== "applicantList") {
        return {
          headerName: col.headerName,
          field: col.field,
          editable: true,
          cellEditor: "agSelectCellEditor",
          cellEditorParams: {
            values: ["미처리", "합격", "불합격"],
          },
          editable: (params) => {
            // activeTab이 documentEvaluation일 때는 모두 편집 가능
            if (activeTab === "documentEvaluation") {
              return true;
            }
            // 그 외의 경우 authority가 admin 또는 owner일 때만 편집 가능
            return authority === "admin" || authority === "owner";
          },
        };
      } else if (col.field === "schedule.scheduled_date") {
        return {
          headerName: col.headerName,
          field: col.field,
          editable: false,
          valueGetter: (params) => {
            // ISO 형식을 YYYY-MM-DD HH:mm 형식으로 변환
            const scheduledDate = params.data.schedule?.scheduled_date;
            return scheduledDate ? formatDateTime(scheduledDate) : "";
          },
          valueSetter: (params) => {
            const editedValue = params.newValue; // 사용자가 입력한 값
            // YYYY-MM-DD HH:mm 형식을 ISO 형식으로 변환
            const date = new Date(editedValue);
            if (!isNaN(date.getTime())) {
              params.data.schedule.scheduled_date = date.toISOString(); // ISO 형식으로 저장
              return true; // 값이 업데이트됨
            }
            return false; // 값이 유효하지 않을 경우 업데이트하지 않음
          },
          valueFormatter: (params) => {
            // ISO 형식을 YYYY-MM-DD HH:mm 형식으로 변환하여 표시
            return params.value ? formatDateTime(params.value) : "";
          },
        };
      } else {
        return {
          headerName: col.headerName,
          field: col.field,
          valueGetter: (params) => {
            const fieldPath = col.field.split(".");
            let value = params.data;
            fieldPath.forEach((key) => {
              value = value ? value[key] : "";
            });
            return value || ""; // undefined 값을 방지
          },
        };
      }
    }),
  ];

  // 콘솔로 데이터와 컬럼 확인
  console.log("Table Columns:", gridColumns); // 정의된 컬럼 확인
  console.log("Row Data:", rowData); // 현재 테이블의 데이터 확인

  return (
    <TableWrap
      style={{
        height: "calc(100vh - 104px)",
        width: "100%",
        minWidth: "100px",
        maxWidth: "100%",
        overflow: "auto",
      }}
    >
      <div
        className="ag-theme-alpine"
        style={{ height: "100%", width: "100%", position: "relative" }}
      >
        {/* 로딩 중 메시지 */}
        {loading && (
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              fontSize: "16px",
              textAlign: "center",
              color: "#888",
              backgroundColor: "rgba(255, 255, 255, 0.8)",
              padding: "10px",
              borderRadius: "8px",
              zIndex: 2,
            }}
          >
            로딩 중...
          </div>
        )}

        {/* 데이터가 없을 때 메시지 */}
        {!loading && rowData.length === 0 && (
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              fontSize: "16px",
              textAlign: "center",
              color: "#6a6a6a",
              backgroundColor: "rgba(255, 255, 255, 0.8)",
              padding: "10px",
              borderRadius: "8px",
              zIndex: 2,
            }}
          >
            {error || "데이터가 없습니다."}
          </div>
        )}

        <AgGridReact
          columnDefs={gridColumns}
          rowData={rowData}
          defaultColDef={{
            resizable: true,
            sortable: true,
            editable: true,
            width: 150,
          }}
          suppressPaginationPanel={true}
          suppressNoRowsOverlay={true}
          frameworkComponents={{
            MultiSelector,
          }}
          onCellValueChanged={(params) => {
            console.log("Updated Field:", params.colDef.field);
            console.log("Updated Value:", params.newValue);

            if (params.colDef.field === "selected") {
              // selected 필드는 handleCellUpdate 호출하지 않음
              return;
            }
            if (params.colDef.field === "teams") {
              const selectedTeams = params.newValue || [];
              console.log("Selected Teams for API:", selectedTeams);

              // 서버로 보내기 전에 필요한 형식으로 데이터 변환
              const formattedTeams = selectedTeams.map((teamName) => ({
                name: teamName,
              }));
              console.log("Formatted Teams for API:", formattedTeams);

              handleCellUpdate({ ...params, newValue: formattedTeams });
            } else {
              handleCellUpdate(params);
            }
          }}
        />

        {/* 하단의 페이지네이션 및 명령 버튼 */}
        <div className="command_container">
          {["jobPostings", "applicantList"].includes(activeTab) ? (
            <div className="command-controls">
              {activeTab === "jobPostings" ? (
                <button
                  onClick={addEmptyRow}
                  // onClick={() => {
                  //   if (authority === "admin" || authority === "owner") {
                  //     addEmptyRow();
                  //   } else {
                  //     alert("권한이 없습니다");
                  //   }
                  // }}
                >
                  공고 추가
                </button>
              ) : (
                <button
                  onClick={addEmptyRow}
                  // onClick={() => {
                  //   if (authority === "admin" || authority === "owner") {
                  //     addEmptyRow();
                  //   } else {
                  //     alert("권한이 없습니다");
                  //   }
                  // }}
                >
                  지원자 추가
                </button>
              )}
              <button
                onClick={() => {
                  if (authority === "admin" || authority === "owner") {
                    deleteSelectedRows();
                  } else {
                    alert("권한이 없습니다");
                  }
                }}
              >
                선택 항목 삭제
              </button>
            </div>
          ) : null}
          {activeTab !== "jobPostings" && (
            <div className="search-controls">
              <input
                type="text"
                placeholder="이름 검색"
                value={searchInput}
                onChange={handleInputChange}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    onSearch(searchInput); // 엔터키로 검색 실행
                  }
                }}
                style={{
                  padding: "5px",
                  marginRight: "10px",
                  borderRadius: "4px",
                  outline: "none",
                  border: "1px solid #ccc",
                }}
              />
              <button
                onClick={() => onSearch(searchInput)} // 검색 실행
                style={{
                  padding: "5px 10px",
                  backgroundColor: "#007bff",
                  color: "#fff",
                  border: "none",
                  borderRadius: "4px",
                  cursor: "pointer",
                }}
              >
                검색
              </button>
            </div>
          )}

          <div className="pagination-controls">
            <select
              className="pagination_select"
              value={pageSize}
              onChange={(e) => onPageSizeChange(Number(e.target.value))}
            >
              <option value={10}>10명</option>
              <option value={20}>20명</option>
              <option value={50}>50명</option>
              <option value={100}>100명</option>
            </select>
            <button onClick={() => onPageChange(page - 1)} disabled={page <= 1 || loading}>
              이전
            </button>

            <span className="pagination_num">
              {loading ? " / " : `${page} / ${totalPagesDisplay}`}
            </span>

            <button
              onClick={() => onPageChange(page + 1)}
              disabled={page >= totalPagesDisplay || loading}
            >
              다음
            </button>
          </div>
        </div>
      </div>
    </TableWrap>
  );
}

export default Table;
