import React from "react";
import { Navigate, useLocation } from "react-router-dom";

const ProtectedRoute = ({ isLoggedIn, children }) => {
  const location = useLocation();

  if (!isLoggedIn) {
    // 로그인 상태가 아니면, 현재 위치를 `state`에 저장하고 로그인 페이지로 리다이렉트
    return <Navigate to="/admin/login" state={{ from: location }} replace />;
  }

  return children; // 로그인 상태라면 자식 컴포넌트 렌더링
};

export default ProtectedRoute;
