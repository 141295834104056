import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Grid,
  Box,
  Typography,
  CircularProgress,
} from "@mui/material";
import { parseISO, format } from "date-fns";
import {
  postAvailableTimes,
  updateInterviewSchedule,
  resetInterviewSchedule,
} from "../../../api/auth";

function TimeScheduleModal({ isOpen, onClose, data, authority, activeTab }) {
  const [dateRange, setDateRange] = useState({
    startDate: new Date(),
    endDate: new Date(new Date().setDate(new Date().getDate() + 7)),
  });
  const [selectedTimes, setSelectedTimes] = useState([]);
  const [isDragging, setIsDragging] = useState(false);
  const [availableTimes, setAvailableTimes] = useState({});
  const [selectedTeam, setSelectedTeam] = useState(null); // 선택된 팀

  const [loading, setLoading] = useState(false);

  const calculateWeekdays = (startDate, numberOfDays) => {
    const weekdays = [];
    let currentDate = new Date(startDate);
    while (weekdays.length < numberOfDays) {
      const day = currentDate.getDay();
      if (day !== 0 && day !== 6) {
        // 0: Sunday, 6: Saturday
        weekdays.push(new Date(currentDate));
      }
      currentDate.setDate(currentDate.getDate() + 1);
    }
    return { startDate: weekdays[0], endDate: weekdays[weekdays.length - 1] };
  };

  useEffect(() => {
    if (isOpen) {
      setSelectedTimes([]);
      setAvailableTimes({});
      setSelectedTeam(null);
      setLoading(true);

      // data.created_at을 사용해 startDate와 endDate를 설정
      if (data?.created_at) {
        const createdAtDate = new Date(data.created_at);
        const nextDay = new Date(createdAtDate);
        nextDay.setDate(createdAtDate.getDate() + 1); // 다음 날로 설정

        const { startDate, endDate } = calculateWeekdays(nextDay, 5); // 주말 제외 평일 5일 계산
        setDateRange({ startDate, endDate });
      }

      const startDateFormatted = formatDateForAPI(dateRange.startDate);
      const endDateFormatted = formatDateForAPI(dateRange.endDate);

      if (activeTab === "firstInterview") {
        // API 호출
        axios
          .get(
            `https://3s2f7glek0.execute-api.ap-northeast-2.amazonaws.com/default?start_date=${startDateFormatted}&end_date=${endDateFormatted}`,
          )
          .then((response) => {
            const mergedAvailableTimes = mergeAvailableTimes(
              response.data.meetingroom1,
              response.data.meetingroom2,
              false, // `firstInterview`일 때
            );

            if (authority === "admin") {
              // 공통 시간대 계산 및 추가
              const commonTimes = getCommonTimes();
              Object.keys(mergedAvailableTimes).forEach((date) => {
                if (!mergedAvailableTimes[date]) mergedAvailableTimes[date] = [];
                mergedAvailableTimes[date] = [
                  ...mergedAvailableTimes[date],
                  ...commonTimes.filter((time) => !mergedAvailableTimes[date].includes(time)), // 중복 방지
                ];
              });
            }

            setAvailableTimes(mergedAvailableTimes);
          })
          .catch((error) => {
            console.error("이용 가능 시간 조회 실패:", error);
          })
          .finally(() => {
            setLoading(false);
          });
      } else if (activeTab === "secondInterview") {
        // `secondInterview`일 경우 로컬에서 데이터 처리
        const mergedAvailableTimes = mergeAvailableTimes([], [], true); // `secondInterview`일 때

        if (authority === "admin") {
          // 공통 시간대 계산 및 추가
          const commonTimes = getCommonTimes();
          Object.keys(mergedAvailableTimes).forEach((date) => {
            if (!mergedAvailableTimes[date]) mergedAvailableTimes[date] = [];
            mergedAvailableTimes[date] = [
              ...mergedAvailableTimes[date],
              ...commonTimes.filter((time) => !mergedAvailableTimes[date].includes(time)), // 중복 방지
            ];
          });
        }

        setAvailableTimes(mergedAvailableTimes);
        setLoading(false);
      }
    }
  }, [isOpen, data?.created_at]);

  const formatDate = (date) => {
    const year = date.getFullYear().toString().slice(-2);
    const month = `0${date.getMonth() + 1}`.slice(-2);
    const day = `0${date.getDate()}`.slice(-2);
    const weekday = date.toLocaleDateString("ko-KR", { weekday: "short" });
    return `${month}.${day} (${weekday})`;
  };

  const formatDateForAPI = (date) => {
    const year = date.getFullYear();
    const month = `0${date.getMonth() + 1}`.slice(-2);
    const day = `0${date.getDate()}`.slice(-2);
    return `${year}-${month}-${day}`;
  };

  // 예약된 시간대를 확인하는 함수 수정 (종료 시간 포함하지 않음)
  const isTimeSlotBooked = (reservation, dateString, timeSlot) => {
    const startTime = new Date(reservation.properties["예약 시간"].date.start);
    let endTime = reservation.properties["예약 시간"].date.end
      ? new Date(reservation.properties["예약 시간"].date.end)
      : new Date(startTime.getTime() + 60 * 60 * 1000); // 종료 시간이 없으면 1시간 후로 간주

    // 종료 시간이 시작 시간과 동일하다면 종료 시간을 1시간 뒤로 설정
    if (startTime.getTime() === endTime.getTime()) {
      endTime = new Date(startTime.getTime() + 60 * 60 * 1000);
    }

    const slotTime = new Date(`${dateString}T${timeSlot}:00+09:00`);

    // 예약된 시간 범위 내에 포함되면 사용 불가, 종료 시간은 포함하지 않음
    return slotTime >= startTime && slotTime < endTime;
  };

  // 예약된 시간대를 병합하여 이용 가능한 시간 계산
  const mergeAvailableTimes = (room1 = [], room2 = [], isSecondInterview = false) => {
    const allTimeSlots = [
      "09:00",
      "10:00",
      "11:00",
      "12:00",
      "13:00",
      "14:00",
      "15:00",
      "16:00",
      "17:00",
      "18:00",
    ];
    const unavailableSlots = {};

    // 오늘 날짜를 기준으로 이전 날짜는 선택 불가 처리
    const today = new Date();
    today.setHours(0, 0, 0, 0); // 시간 제거

    // 각 회의실의 예약 시간을 시간 단위로 나누어 unavailableSlots에 기록합니다.
    if (!isSecondInterview) {
      // `firstInterview`일 경우, 예약된 시간 처리
      [room1, room2].forEach((room) => {
        room.forEach((reservation) => {
          const date = reservation.properties["예약 시간"].date.start.split("T")[0];
          let startTime = new Date(reservation.properties["예약 시간"].date.start);
          let endTime = reservation.properties["예약 시간"].date.end
            ? new Date(reservation.properties["예약 시간"].date.end)
            : new Date(startTime.getTime() + 60 * 60 * 1000); // 종료 시간이 없으면 1시간 후로 간주

          // 종료 시간이 시작 시간과 같다면 종료 시간을 1시간 뒤로 설정
          if (startTime.getTime() === endTime.getTime()) {
            endTime = new Date(startTime.getTime() + 60 * 60 * 1000);
          }

          if (!unavailableSlots[date]) {
            unavailableSlots[date] = new Set();
          }

          // 예약된 시간을 1시간 단위로 쪼개어 저장, 종료 시간은 포함하지 않음
          let currentTime = new Date(startTime);
          while (currentTime < endTime) {
            const hourSlot = `${
              currentTime.getHours() < 10 ? "0" : ""
            }${currentTime.getHours()}:00`;
            unavailableSlots[date].add(hourSlot);
            currentTime.setHours(currentTime.getHours() + 1);
          }
        });
      });
    }

    // 미팅룸 1과 미팅룸 2 중 하나라도 사용 가능한 시간대는 사용 가능으로 설정
    const availableTimes = {};

    // 예약된 시간대를 확인하고 사용 가능한 시간대를 계산
    const allDates = getWeekdaysInRange(dateRange.startDate, dateRange.endDate);
    allDates.forEach((date) => {
      const dateString = formatDateForAPI(date);
      availableTimes[dateString] = [];

      allTimeSlots.forEach((timeSlot) => {
        // 오늘보다 이전 날짜는 선택 불가능으로 처리
        if (date < today) {
          availableTimes[dateString].push(null); // 이전 날짜의 시간대는 사용 불가능
          return;
        }

        // `secondInterview`일 경우, 모든 시간 선택 가능
        if (isSecondInterview) {
          availableTimes[dateString].push(timeSlot);
        } else {
          // `firstInterview`일 경우, 예약된 시간 제외
          const isRoom1Booked = room1.some((reservation) =>
            isTimeSlotBooked(reservation, dateString, timeSlot),
          );
          const isRoom2Booked = room2.some((reservation) =>
            isTimeSlotBooked(reservation, dateString, timeSlot),
          );

          // 하나라도 예약되지 않은 시간대는 선택 가능
          if (!isRoom1Booked || !isRoom2Booked) {
            availableTimes[dateString].push(timeSlot);
          }
        }
      });
    });

    // 예약이 없는 날짜 처리
    allDates.forEach((date) => {
      const dateString = formatDateForAPI(date);
      if (!availableTimes[dateString]) {
        availableTimes[dateString] = [...allTimeSlots];
      }
    });

    return availableTimes;
  };

  const handleTimeSelect = (date, time) => {
    const dateString = format(date, "yyyy-MM-dd");
    const timeString = `${dateString} ${time}`;
    setSelectedTimes((prev) => {
      if (prev.includes(timeString)) {
        return prev.filter((t) => t !== timeString);
      } else {
        return [...prev, timeString];
      }
    });
  };

  const handleMouseDown = (date, time) => {
    setIsDragging(true);
    handleTimeSelect(date, time);
  };

  const handleMouseEnter = (date, time) => {
    if (isDragging) {
      handleTimeSelect(date, time);
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const getWeekdaysInRange = (start, end) => {
    const dates = [];
    let currentDate = new Date(start);
    while (currentDate <= end) {
      const day = currentDate.getDay();
      if (day !== 0 && day !== 6) {
        dates.push(new Date(currentDate));
      }
      currentDate.setDate(currentDate.getDate() + 1);
    }
    return dates;
  };

  const getTimesForDay = () => {
    const times = [];
    for (let hour = 9; hour <= 18; hour++) {
      times.push(`${hour < 10 ? "0" : ""}${hour}:00`);
    }
    return times;
  };

  const weekdays = getWeekdaysInRange(dateRange.startDate, dateRange.endDate);
  const times = getTimesForDay();

  const handleConfirm = async () => {
    if (selectedTimes.length === 0) {
      alert("시간대를 선택해주세요.");
      return;
    }

    let confirmMessage = "";

    // 선택된 하나의 일정 표시
    const selectedTime = selectedTimes[0];
    const formattedSelectedTime = new Date(selectedTime).toLocaleString("ko-KR", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      weekday: "short",
      hour: "2-digit",
      minute: "2-digit",
    });

    // admin이면서 schedule_status가 "미정"일 때 메시지 설정
    if (authority === "admin" && data?.schedule_status === "미정") {
      const teamsWithoutStartTimes = data.schedule.available_times
        .filter((team) => !team.start_times || team.start_times.every((time) => time === null))
        .map((team) => team.team);

      if (teamsWithoutStartTimes.length === 0) {
        confirmMessage = `모든 팀이 일정을 선택하였습니다.\n${formattedSelectedTime} 일정을 확정하시겠습니까?`;
      } else {
        confirmMessage = `${teamsWithoutStartTimes.join(
          ", ",
        )}이 일정을 선택하지 않았습니다.\n그럼에도 ${formattedSelectedTime} 일정을 확정하시겠습니까?`;
      }
    }

    // admin이면서 schedule_status가 "대기중"일 때 메시지 설정
    if (authority === "admin" && data?.schedule_status === "대기중") {
      if (selectedTimes.length > 1) {
        alert("하나의 일정으로 선택 후 확정할 수 있습니다.\n다시 선택해주세요.");
        setSelectedTimes([]);
        return;
      }

      confirmMessage = `모든 팀이 일정을 선택하였습니다.\n${formattedSelectedTime} 일정을 확정하시겠습니까?`;
    }

    // admin이면서 schedule_status가 "확정"일 때 메시지 설정
    if (authority === "admin" && data?.schedule_status === "확정") {
      if (selectedTimes.length > 1) {
        alert("하나의 일정으로 선택 후 확정할 수 있습니다.\n다시 선택해주세요.");
        setSelectedTimes([]);
        return;
      }

      confirmMessage = `${formattedSelectedTime} 로 일정을 변경하시겠습니까?`;
    }

    // team_lead 또는 owner이면서 schedule_status가 "미정"일 때 메시지 설정
    if ((authority === "team_lead" || authority === "owner") && data?.schedule_status === "미정") {
      confirmMessage = `해당 시간들로 일정을 선택하시겠습니까?`;
    }

    // team_lead 또는 owner이면서 schedule_status가 "대기중"일 때 메시지 설정
    if (
      (authority === "team_lead" || authority === "owner") &&
      data?.schedule_status === "대기중"
    ) {
      confirmMessage = `해당 시간들로 일정을 변경하시겠습니까?`;
    }

    // confirmMessage가 설정되었을 경우 사용자 확인
    if (confirmMessage) {
      const userConfirmed = window.confirm(confirmMessage);
      if (!userConfirmed) {
        return; // 사용자가 취소를 누르면 함수 종료
      }
    }

    const formattedTimes = selectedTimes.map((time) => [new Date(time).toISOString()]);
    const payload = { times: formattedTimes };

    console.log("data.schedule.id", data?.schedule?.id);
    console.log("Payload:", payload);

    try {
      if (authority === "admin") {
        // Admin일 경우 updateInterviewSchedule 호출
        const updatePayload = {
          interview_type: data.schedule.interview_type,
          scheduled_date: selectedTime,
        };
        const response = await updateInterviewSchedule(data.schedule.id, updatePayload);
        console.log("면접 일정 수정 API 호출 성공:", response);

        alert("면접 일정이 확정되었습니다.");
      } else if (authority === "team_lead" || authority === "owner") {
        // Team Lead일 경우 postAvailableTimes 호출
        const response = await postAvailableTimes(data.schedule.id, payload);
        console.log("가능 시간대 저장 API 호출 성공:", response);

        alert("가능 시간대가 저장되었습니다.");
      }

      window.location.reload(); // reload
      onClose(); // 모달 닫기
    } catch (error) {
      console.error("API 호출 오류:", error.response || error.message);
      const errorMessage = error.response?.data?.message || "시간대 저장 중 문제가 발생했습니다.";
      alert(errorMessage);
    }
  };

  const handleTeamSelect = (team) => {
    // 팀의 start_times가 비어있거나 null인 경우 알림 표시
    if (!team.start_times || team.start_times.every((time) => time === null)) {
      alert("해당 팀은 아직 선택한 일정이 없습니다.");
      return;
    }

    // 팀 선택 로직 (같은 팀 클릭 시 해제)
    // 선택 중이던 시간을 초기화하고 팀 선택
    setSelectedTimes([]); // 선택한 시간 초기화
    setSelectedTeam((prevTeam) => (prevTeam?.team === team.team ? null : team));
  };

  const getCommonTimes = () => {
    const availableTimes = data?.schedule?.available_times || [];
    if (availableTimes.length === 0) return [];

    // 모든 팀의 start_times 배열의 교집합 계산
    const commonTimes = availableTimes
      .map((team) => team.start_times.map((time) => new Date(time).toISOString()))
      .reduce((common, times) => common.filter((time) => times.includes(time)));

    // 공통 시간대를 'YYYY-MM-DD HH:mm' 형식으로 변환
    return commonTimes.map((time) => {
      const date = new Date(time);
      const dateString = formatDateForAPI(date);
      const timeString = `${date.getHours().toString().padStart(2, "0")}:00`;
      return `${dateString} ${timeString}`;
    });
  };

  // 면접 일정 초기화 핸들러
  const handleResetSchedule = async () => {
    if (!data?.schedule?.id) {
      alert("일정을 초기화할 수 없습니다. 잘못된 데이터입니다.");
      return;
    }

    const userConfirmed = window.confirm(
      "정말로 면접 일정을 초기화하시겠습니까? \n일정조율의 처음 단계부터 다시 시작해야합니다.",
    );
    if (!userConfirmed) {
      return; // 사용자가 취소를 누르면 함수 종료
    }

    // setLoading(true); // 로딩 상태 시작
    try {
      const response = await resetInterviewSchedule(data.schedule.id);
      console.log("면접 일정 초기화 성공:", response);

      alert("면접 일정이 초기화되었습니다.");
      onClose(); // 모달 닫기
      window.location.reload(); // 새로고침
    } catch (error) {
      console.error("면접 일정 초기화 오류:", error);
      alert("면접 일정 초기화 중 문제가 발생했습니다.");
    } finally {
      // setLoading(false); // 로딩 상태 종료
    }
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      sx={{
        "& .MuiPaper-root": {
          maxWidth: "500px", // 모달 너비
          minHeight: "300px", // 모달 최소 높이
          // maxHeight: "400px", // 모달 최대 높이
          padding: "8px", // 전체 패딩
        },
      }}
    >
      {loading ? (
        <>
          <DialogTitle sx={{ fontSize: "16px", padding: "8px" }}>
            데이터를 불러오는 중...
          </DialogTitle>
          <DialogContent
            onMouseUp={handleMouseUp}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "250px", // 로딩 상태에서 높이 설정
              padding: "12px",
            }}
          >
            <Box>
              <CircularProgress />
            </Box>
          </DialogContent>
        </>
      ) : (
        <>
          <DialogTitle
            sx={{
              fontSize: "16px",
              padding: "8px",
            }}
          >
            {data?.applicant?.name}님의 면접 일정 조율
          </DialogTitle>
          <DialogContent
            onMouseUp={handleMouseUp}
            sx={{
              padding: "12px",
            }}
          >
            {/* 참여 팀 표시 */}
            {data?.schedule?.available_times && data.schedule.available_times.length > 0 && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  flexWrap: "wrap",
                  gap: "16px",
                  margin: "0 0 12px 0",
                }}
              >
                {data.schedule.available_times.map((item) => (
                  <Box
                    key={item.team}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "4px",
                      backgroundColor: selectedTeam?.team === item.team ? "#e3f2fd" : "#f5f5f5", // 선택된 팀은 파란색 배경
                      color: "#000",
                      padding: "4px 8px",
                      cursor: "pointer", // 클릭 가능한 박스로 변경
                      border:
                        selectedTeam?.team === item.team
                          ? "2px solid #1976d2"
                          : "2px solid transparent", // 선택된 경우 테두리 강조
                      borderRadius: "4px",
                      transition: "background-color 0.3s, border 0.3s", // 부드러운 효과
                      fontSize: "12px",
                      fontWeight: "bold",
                    }}
                    onClick={() => handleTeamSelect(item)}
                  >
                    <input
                      type="checkbox"
                      checked={
                        Array.isArray(item.start_times) &&
                        item.start_times.some((time) => time !== null)
                      }
                      readOnly
                      style={{ pointerEvents: "none" }} // 체크박스는 비활성화
                    />
                    <Typography sx={{ fontSize: "12px" }}>{item.team}</Typography>
                  </Box>
                ))}
              </Box>
            )}

            <Box>
              <Grid container spacing={1}>
                {weekdays.map((date, index) => {
                  const dateString = format(date, "yyyy-MM-dd"); // 날짜를 올바른 형식으로 포맷팅 (UTC 문제 해결)

                  return (
                    <Grid item xs={2.4} key={index}>
                      <Typography
                        variant="h6"
                        align="center"
                        sx={{
                          fontSize: "14px",
                          marginBottom: "4px",
                        }}
                      >
                        {formatDate(date)}
                      </Typography>
                      <Box>
                        {times.map((time) => {
                          const isAvailable =
                            selectedTeam === null
                              ? availableTimes[dateString]?.includes(time)
                              : selectedTeam.start_times?.some(
                                  (selectedTime) =>
                                    new Date(selectedTime).toISOString() ===
                                    new Date(`${dateString}T${time}:00`).toISOString(),
                                );

                          // 공통 시간대를 확인 (팀이 선택되지 않은 경우에만 체크)
                          const isCommonTime =
                            selectedTeam === null &&
                            getCommonTimes().includes(`${dateString} ${time}`);

                          // 선택/드래그 가능 여부 설정
                          const isSelectable = selectedTeam === null && isAvailable;

                          // 팀 선택 상태에서의 강조 색상 설정
                          const isTeamSelectedTime =
                            selectedTeam !== null &&
                            selectedTeam.start_times?.some(
                              (selectedTime) =>
                                new Date(selectedTime).toISOString() ===
                                new Date(`${dateString}T${time}:00`).toISOString(),
                            );

                          return (
                            <Box
                              key={`${dateString}-${time}`}
                              sx={{
                                p: 0.5,
                                m: 0.5,
                                textAlign: "center",
                                border: "1px solid #ccc",
                                borderRadius: "4px",
                                cursor: isSelectable ? "pointer" : "not-allowed",
                                userSelect: "none",
                                fontSize: "12px",
                                backgroundColor: selectedTimes.includes(`${dateString} ${time}`)
                                  ? "#1976d2" // 사용자가 선택한 시간은 파란색
                                  : isCommonTime
                                  ? "#4fbe6e" // 공통 시간대는 녹색
                                  : isTeamSelectedTime
                                  ? "#1976d2" // 팀이 선택한 시간은 파란색
                                  : isAvailable
                                  ? "#f8f8f8" // 일반적으로 사용 가능한 시간은 회색
                                  : "#d3d3d3", // 사용 불가능한 시간은 연한 회색
                                color:
                                  selectedTimes.includes(`${dateString} ${time}`) ||
                                  isTeamSelectedTime ||
                                  isCommonTime
                                    ? "#fff"
                                    : "#000",
                                fontWeight: isTeamSelectedTime || isCommonTime ? "bold" : "normal",
                              }}
                              onMouseDown={() => isSelectable && handleMouseDown(date, time)}
                              onMouseEnter={() => isSelectable && handleMouseEnter(date, time)}
                              onMouseUp={handleMouseUp}
                            >
                              {time}
                            </Box>
                          );
                        })}
                      </Box>
                    </Grid>
                  );
                })}
              </Grid>
            </Box>
          </DialogContent>
        </>
      )}

      <DialogActions
        sx={{
          padding: "8px",
          justifyContent: "space-between",
        }}
      >
        <div>
          {authority === "admin" && (
            <Button onClick={handleResetSchedule} color="error">
              일정 초기화
            </Button>
          )}
        </div>
        <div>
          <Button onClick={onClose} color="secondary">
            취소
          </Button>
          <Button onClick={handleConfirm} color="primary">
            일정 확정
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
}

export default TimeScheduleModal;
