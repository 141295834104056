import styled from "@emotion/styled";

export const TabsContainer = styled.div`
  display: flex;
  white-space: nowrap;
  padding: 8px 0;
  background-color: #6a0dad;

  .tab-item {
    display: inline-flex;
    align-items: center;
    padding: 0 12px;
    height: 32px;
    color: #fff;
    cursor: pointer;
    border-bottom: 2px solid transparent;
    transition: border-bottom 0.3s ease;
  }

  .tab-item.active {
    border-bottom: 2px solid #fff;
    font-weight: bold;
  }

  .tab-item span {
    color: inherit;
    text-decoration: none;
  }

  .tab-item:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }

  @media screen and (max-width: 992px) {
    flex-direction: column;
    .tab-item {
      width: 100%;
      text-align: center;
      padding: 10px;
    }
  }
`;
