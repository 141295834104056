// hooks/useDateFormatter.js
const useDateFormatter = () => {
  /**
   * ISO 형식의 날짜 문자열을 YYYY-MM-DD 형식으로 변환
   * @param {string} dateString ISO 형식의 날짜 문자열
   * @returns {string} YYYY-MM-DD 형식으로 변환된 문자열
   */
  const formatDate = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    if (isNaN(date.getTime())) return "";
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  /**
   * ISO 형식의 날짜 문자열을 YYYY-MM-DD HH:mm 형식으로 변환
   * @param {string} isoDate ISO 형식의 날짜 문자열
   * @returns {string} YYYY-MM-DD HH:mm 형식의 문자열
   */
  const formatDateTime = (isoDate) => {
    if (!isoDate) return "";
    const date = new Date(isoDate);
    if (isNaN(date.getTime())) return "";

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");

    return `${year}-${month}-${day} ${hours}:${minutes}`;
  };

  return { formatDate, formatDateTime };
};

export default useDateFormatter;
