import styled from "@emotion/styled";

export const LoginWrap = styled.section`
  position: relative;
  margin: 0;
  padding: 0;
  width: 100vw;
  height: 100vh;
  background: #f8f8f8;

  .admin_login {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    border-radius: 15px;
    padding: 2rem;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  }

  .login_header {
    position: relative;
    font-size: 1.5rem;
    font-weight: 800;
    position: relative;
    padding-top: 2rem;
    padding-bottom: 1rem;
    text-align: left;
  }

  .login_logo {
    position: absolute;
    top: -2rem;
    left: 0;
    transform: translateY(-50%);
    z-index: 20;
    width: 6rem;

    & img {
      width: 100%;
    }
  }

  .login_form_tit {
    margin-top: 1.5rem;
    margin-bottom: 0.5rem;
    font-weight: bold;
    text-align: left;
  }

  .login_form {
    position: relative;
    font-size: 1.3rem;
    font-weight: 100;
    letter-spacing: 0;

    & label img {
      position: absolute;
      left: 1rem;
      top: 50%;
      transform: translateY(-50%);
      z-index: 20;
      width: 1.6rem;
    }
  }
  .login_input {
    width: 30rem;
    padding: 1.5rem;
    padding-left: 3.8rem;
    border: 1px solid #b1b1b1;
    border-radius: 10px;
  }

  .login_btn {
    background-color: rgb(34, 34, 34);
    color: #fff;
    width: 100%;
    cursor: pointer;
    display: inline-block;
    padding: 1.5rem 0;
    font-size: 1.2rem;
    font-weight: bold;
    border: 0;
    border-radius: 10px;
    transition: 0.5s;
    margin-top: 2.5rem;
  }

  .copyright_t {
    color: #b1b1b1;
    font-weight: normal;
    text-align: center;
    margin-top: 3rem;
  }

  @media screen and (max-width: 992px) {
  }
`;
