import styled from "@emotion/styled";
import TitleBgImg from "../../assets/images/aboutUs-title-bg.png";

export const AboutUsTitleWrap = styled.section`
    position: relative;
    width: 100%;
    height: 400px;
    padding: 130px 0 155px;
    text-align: center;
    .title-bg-img {
        width: 100%;
        height: 490px;
        position: absolute;
        background: url(${TitleBgImg}) no-repeat center;
        background-size: cover;
        filter: brightness(0.2);
        z-index: 0;
        top: -90px;
        left: 0;
    }
    .title-wrap {
        position: relative;
        display: flex;
        flex-direction: column;
        gap: 20px;
        z-index: 1;
    }
    .title-wrap .title-sub {
    }
    .title-wrap .title-sub .svg {
    }
    .title-wrap .title-sub .svg path {
    }
    .title-wrap .title-desc {
        color: #fff;
        text-align: center;
        font-family: "Pretendard";
        font-size: 60px;
        font-weight: 800;
    }
    .title-wrap .title-desc .title-desv-item {
    }

    @media screen and (max-width: 992px) {
        position: relative;
        width: 100%;
        height: 240px;
        padding: 87px 0;
        text-align: center;
        .title-bg-img {
            height: 330px;
        }
        .title-wrap {
            gap: 10px;
        }
        .title-wrap .title-desc {
            color: #fff;
            text-align: center;
            font-family: "Pretendard";
            font-size: 32px;
            font-weight: 800;
        }
    }
`;
