import React from "react";
import { TabsContainer } from "./Tabs.styled";

function Tabs({ activeTab, setActiveTab, tabs }) {
  return (
    <TabsContainer>
      {tabs.map((tab) => (
        <div
          key={tab.key}
          className={`tab-item ${activeTab === tab.key ? "active" : ""}`}
          onClick={() => setActiveTab(tab.key)}
        >
          <span>{tab.label}</span>
        </div>
      ))}
    </TabsContainer>
  );
}

export default Tabs;
