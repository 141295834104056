import React, { useEffect, useState } from "react";

function Test() {
  // const [calendarData, setCalendarData] = useState([]);
  // const [loading, setLoading] = useState(true);

  // useEffect(() => {
  //   // 비동기 함수 정의
  //   const fetchNotionCalendarData = async () => {
  //     const databaseId = "ef6c2eed-acf7-4b46-aea2-303b809653b7";
  //     const url = `https://api.notion.com/v1/databases/${databaseId}/query`;

  //     try {
  //       // 데이터 가져오기 요청
  //       const response = await fetch(url, {
  //         method: "POST",
  //         headers: {
  //           Authorization: `Bearer ${process.env.REACT_APP_NOTION_SECRET}`,
  //           "Notion-Version": "2022-06-28",
  //           "Content-Type": "application/json",
  //         },
  //       });

  //       if (!response.ok) {
  //         throw new Error(`HTTP error! status: ${response.status}`);
  //       }

  //       const result = await response.json();
  //       setCalendarData(result.results); // 데이터 설정
  //     } catch (error) {
  //       console.error("Failed to fetch Notion data:", error);
  //     } finally {
  //       setLoading(false); // 로딩 상태 종료
  //     }
  //   };

  //   fetchNotionCalendarData();
  // }, []);

  return (
    <div>
      <h1>test</h1>
    </div>
  );
}

export default Test;
