import React, { useState, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import Index from "../index/Index";
import Login from "../login/Login";
import Employment from "../employment/Employment";
import Test from "../test/Test";
import ProtectedRoute from "../../components/ProtectedRoute";
import { MainWrap } from "./Main.styled";

function Main() {
  const [isLoggedIn, setIsLoggedIn] = useState(null); // 초기 상태를 null로 설정
  const [userName, setUserName] = useState("");
  const [authority, setAuthority] = useState("");
  const [teamName, setTeamName] = useState("");

  useEffect(() => {
    // 로컬 스토리지에서 로그인 상태 및 사용자 정보 불러오기
    const savedUserName = localStorage.getItem("user_name");
    const savedAuthority = localStorage.getItem("authority");
    const savedTeamName = localStorage.getItem("team_name");

    if (savedUserName) {
      setIsLoggedIn(true);
      setUserName(savedUserName);
      setAuthority(savedAuthority || "");
      setTeamName(savedTeamName || "");
    } else {
      setIsLoggedIn(false);
    }
  }, []);

  // 로그인 상태를 확인하기 전에는 아무것도 렌더링하지 않음
  if (isLoggedIn === null) {
    return null;
  }

  return (
    <MainWrap>
      <Routes>
        {/* 로그인 페이지 */}
        <Route
          path="/login"
          element={
            <Login
              setIsLoggedIn={setIsLoggedIn}
              setUserName={setUserName}
              setAuthority={setAuthority}
              setTeamName={setTeamName}
            />
          }
        />

        {/* 보호된 경로 */}
        <Route
          path="/"
          element={
            <ProtectedRoute isLoggedIn={isLoggedIn}>
              <Index
                isLoggedIn={isLoggedIn}
                setIsLoggedIn={setIsLoggedIn}
                userName={userName}
                authority={authority}
                teamName={teamName}
              >
                <Employment />
              </Index>
            </ProtectedRoute>
          }
        />
        <Route
          path="/test"
          element={
            <ProtectedRoute isLoggedIn={isLoggedIn}>
              <Index
                isLoggedIn={isLoggedIn}
                setIsLoggedIn={setIsLoggedIn}
                userName={userName}
                authority={authority}
                teamName={teamName}
              >
                <Test />
              </Index>
            </ProtectedRoute>
          }
        />
      </Routes>
    </MainWrap>
  );
}

export default Main;
