import React, { useState } from "react";
import { login } from "../../api/auth";
import { LoginWrap } from "./Login.styled";
import userNameImg from "../../../assets/images/admin_user_name.png";
import passwordImg from "../../../assets/images/admin_password.png";
import { useNavigate, useLocation } from "react-router-dom";

function Login({ setIsLoggedIn, setUserName, setAuthority, setTeamName }) {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  const location = useLocation();

  const handleLogin = async (event) => {
    event.preventDefault();
    try {
      if (!username || !password) {
        setErrorMessage("아이디와 비밀번호를 입력해 주세요.");
        return;
      }

      const data = await login(username, password);

      // 응답에서 필요한 데이터를 로컬 스토리지에 저장
      localStorage.setItem("user_name", username);
      localStorage.setItem("authority", data.authority); // 권한 저장
      localStorage.setItem("team_name", data.team_name); // 팀명 저장

      // 상태 업데이트
      setUserName(username);
      setAuthority(data.authority);
      setTeamName(data.team_name);

      setIsLoggedIn(true); // 로그인 성공 시 상태 업데이트
      alert(`${username}님, 환영합니다!`);

      // 이전 경로로 리다이렉트 (state에 저장된 경로 또는 기본 경로 "/admin")
      const redirectPath = location.state?.from?.pathname || "/admin";
      const searchParams = location.state?.from?.search || ""; // 쿼리 파라미터 유지
      navigate(redirectPath + searchParams, { replace: true });
    } catch (error) {
      setErrorMessage(error.message);
    }
  };

  return (
    <LoginWrap>
      <form onSubmit={handleLogin} className="admin_login">
        <h2 className="login_header">Admin Login</h2>
        <div className="login_form_tit">아이디</div>
        <div className="login_form">
          <label htmlFor="username">
            <img src={userNameImg} alt="아이디 아이콘" />
          </label>
          <input
            id="username"
            type="text"
            placeholder="아이디"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            autoComplete="off"
            className="login_input"
          />
        </div>
        <div className="login_form_tit">비밀번호</div>
        <div className="login_form">
          <label htmlFor="password">
            <img src={passwordImg} alt="비밀번호 아이콘" />
          </label>
          <input
            id="password"
            type="password"
            placeholder="비밀번호"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            autoComplete="off"
            className="login_input"
          />
        </div>
        {errorMessage && <div className="error_message">{errorMessage}</div>}
        <button type="submit" className="login_btn">
          로그인
        </button>
      </form>
    </LoginWrap>
  );
}

export default Login;
