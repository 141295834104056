import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Grid,
  TextField,
  Box,
  Typography,
  CircularProgress,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from "@mui/material";

import { sendEmailToApplicants, sendTextToApplicants } from "../../../api/auth";
import useDateFormatter from "../../../hooks/useDateFormatter";

const templates = {
  서류합격: {
    sms: "축하합니다! {name}님, {jobTitle} 서류전형에 합격하셨습니다.",
    email: {
      subject: "{jobTitle} 서류전형 합격 안내",
      message: "축하합니다! {name}님, 귀하의 서류가 통과되었습니다. 다음 전형을 준비해주세요.",
    },
  },
  서류불합격: {
    sms: "{name}님, {jobTitle} 서류전형 결과 아쉽게도 불합격하셨습니다.",
    email: {
      subject: "{jobTitle} 서류전형 결과 안내",
      message: "{name}님, 안녕하세요. 귀하의 서류전형 결과 아쉽게도 불합격하셨습니다.",
    },
  },
  "1차면접일정": {
    sms: "{name}님, {jobTitle} 1차 면접 일정은 {meetingDate}입니다.",
    email: {
      subject: "{jobTitle} 1차 면접 일정 안내",
      message:
        "{name}님, 안녕하세요. {jobTitle} 1차 면접 일정은 {meetingDate}입니다. 확인 부탁드립니다.",
    },
  },
  "1차합격": {
    sms: "축하합니다! {name}님, {jobTitle} 1차 면접에 합격하셨습니다.",
    email: {
      subject: "{jobTitle} 1차 면접 합격 안내",
      message:
        "축하합니다! {name}님, {jobTitle} 1차 면접에 합격하셨습니다. 다음 전형을 준비해주세요.",
    },
  },
  "1차불합격": {
    sms: "{name}님, {jobTitle} 1차 면접 결과 아쉽게도 불합격하셨습니다.",
    email: {
      subject: "{jobTitle} 1차 면접 결과 안내",
      message: "{name}님, 안녕하세요. {jobTitle} 1차 면접 결과 아쉽게도 불합격하셨습니다.",
    },
  },
  최종면접일정: {
    sms: "{name}님, {jobTitle} 최종 면접 일정은 {meetingDate}입니다.",
    email: {
      subject: "{jobTitle} 최종 면접 일정 안내",
      message:
        "{name}님, 안녕하세요. {jobTitle} 최종 면접 일정은 {meetingDate}입니다. 확인 부탁드립니다.",
    },
  },
  최종합격: {
    sms: "축하합니다! {name}님, {jobTitle} 최종 합격하셨습니다.",
    email: {
      subject: "{jobTitle} 최종 합격 안내",
      message: "축하합니다! {name}님, 귀하가 {jobTitle} 최종 합격하셨습니다.",
    },
  },
  최종불합격: {
    sms: "{name}님, {jobTitle} 최종 면접 결과 아쉽게도 불합격하셨습니다.",
    email: {
      subject: "{jobTitle} 최종 면접 결과 안내",
      message: "{name}님, 안녕하세요. {jobTitle} 최종 면접 결과 아쉽게도 불합격하셨습니다.",
    },
  },
  기타: {
    sms: "",
    email: {
      subject: "",
      message: "",
    },
  },
};

// 템플릿에서 동적으로 메시지를 생성
const generateMessage = (template, variables) => {
  return template.replace(/{(\w+)}/g, (_, key) => variables[key] || "");
};

const SendMessageModal = ({ isOpen, onClose, onSend, data }) => {
  const [loading, setLoading] = useState(false);
  const [messageType, setMessageType] = useState("sms"); // 기본값 SMS
  const [recipient, setRecipient] = useState(""); // 수신자
  const [subject, setSubject] = useState(""); // 이메일 제목
  const [message, setMessage] = useState(""); // 메시지 내용
  const [stage, setStage] = useState(""); // 지원 단계

  const { formatDateTime } = useDateFormatter();

  const updateMessage = (newMessageType, newStage) => {
    const name = data?.name || "지원자";
    const jobTitle = data?.recruitment?.title || "채용공고";

    // 면접 일정 데이터를 포맷
    const meetingDate =
      newStage === "1차면접일정"
        ? formatDateTime(data?.first_meeting) || "[1차 면접 일정 미정]"
        : newStage === "최종면접일정"
        ? formatDateTime(data?.second_meeting) || "[최종 면접 일정 미정]"
        : "";

    if (templates[newStage]) {
      if (newMessageType === "sms") {
        setMessage(generateMessage(templates[newStage].sms, { name, jobTitle, meetingDate }));
        setSubject(""); // SMS는 제목이 필요하지 않음
      } else if (newMessageType === "email") {
        setSubject(
          generateMessage(templates[newStage].email.subject, { name, jobTitle, meetingDate }),
        );
        setMessage(
          generateMessage(templates[newStage].email.message, { name, jobTitle, meetingDate }),
        );
      }
    }
  };

  useEffect(() => {
    if (isOpen) {
      // 모달 열릴 때 초기 데이터 설정
      const initialStage = "서류합격";
      setStage(initialStage); // 기본 지원 단계 설정
      setMessageType("sms"); // 기본 메시지 타입 설정
      setRecipient(data?.phone_number || ""); // 초기 연락처 설정
      updateMessage("sms", initialStage); // 초기 메시지 업데이트
    }
  }, [isOpen, data]);

  const handleStageChange = (newStage) => {
    setStage(newStage);
    updateMessage(messageType, newStage); // 메시지 업데이트
  };

  const handleTypeChange = (newType) => {
    setMessageType(newType);
    // 타입에 따라 수신자 설정
    if (newType === "sms") {
      setRecipient(data?.phone_number || ""); // 연락처 설정
    } else if (newType === "email") {
      setRecipient(data?.email || ""); // 이메일 설정
    }
    updateMessage(newType, stage); // 메시지 업데이트
  };

  const handleSend = async () => {
    if (!recipient || !message || (messageType === "email" && !subject)) {
      alert("필수 정보를 입력해주세요.");
      return;
    }

    setLoading(true);
    try {
      if (messageType === "sms") {
        // 문자 API 호출
        await sendTextToApplicants(recipient, message);
      } else if (messageType === "email") {
        // 이메일 API 호출
        await sendEmailToApplicants(subject, message, [recipient]);
      }
      alert("메시지가 성공적으로 전송되었습니다.");
      onClose();
    } catch (error) {
      console.error("메시지 전송 실패:", error);
      // 에러 메시지 alert에 표시
      alert(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>문자/이메일 전송</DialogTitle>
      <DialogContent>
        {loading ? (
          <Box display="flex" justifyContent="center" alignItems="center" height="150px">
            <CircularProgress />
          </Box>
        ) : (
          <Box>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="subtitle1" gutterBottom>
                  타입
                </Typography>
                <Box>
                  <Button
                    variant={messageType === "sms" ? "contained" : "outlined"}
                    onClick={() => handleTypeChange("sms")}
                    sx={{ marginRight: 1 }}
                  >
                    문자
                  </Button>
                  <Button
                    variant={messageType === "email" ? "contained" : "outlined"}
                    onClick={() => handleTypeChange("email")}
                  >
                    이메일
                  </Button>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel>지원 단계</InputLabel>
                  <Select
                    value={stage}
                    onChange={(e) => handleStageChange(e.target.value)}
                    label="지원 단계"
                  >
                    {Object.keys(templates).map((key) => (
                      <MenuItem key={key} value={key}>
                        {key}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="수신자"
                  placeholder={messageType === "sms" ? "010-1234-5678" : "example@email.com"}
                  value={recipient}
                  onChange={(e) => setRecipient(e.target.value)}
                />
              </Grid>
              {messageType === "email" && (
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="제목"
                    value={subject}
                    onChange={(e) => setSubject(e.target.value)}
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="메시지 내용"
                  multiline
                  rows={4}
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                />
              </Grid>
            </Grid>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          취소
        </Button>
        <Button onClick={handleSend} color="primary" disabled={loading}>
          전송
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SendMessageModal;
