import styled from "@emotion/styled";

export const EmploymentWrap = styled.section`
  .con-container {
    display: flex;
    width: 100%;
  }

  .ag-cell-inline-editing {
    background-color: #fff;
  }

  @media screen and (max-width: 992px) {
    flex-direction: column;
    .con-container {
      flex-direction: column;
    }
  }
`;
