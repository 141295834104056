import api from "./api";
// 로그인 API 요청 함수
export const login = async (username, password) => {
  try {
    const response = await api.post("/users/login/", { username, password });
    return response.data; // session_id 포함한 데이터 반환
  } catch (error) {
    const status = error.response?.status;
    if (status === 400) throw new Error("비밀번호가 틀렸습니다.");
    if (status === 403) throw new Error("계정이 비활성화되었습니다.");
    if (status === 404) throw new Error("사용자를 찾을 수 없습니다.");
    throw new Error("로그인 중 문제가 발생했습니다.");
  }
};
// 로그아웃 API 요청 함수
export const logout = async () => {
  try {
    const response = await api.post("/users/logout/");
    return response.data;
  } catch (error) {
    throw new Error("로그아웃 중 문제가 발생했습니다.");
  }
};
// 사용자 정보 API 요청 함수
export const userinfo = async () => {
  try {
    const response = await api.get("/users/info/");
    return response.data;
  } catch (error) {
    throw new Error("사용자 정보를 가져오는 중 문제가 발생했습니다.");
  }
};

// 모든 채용 공고 조회
export const getAllRecruitments = async (page = 1, pageSize = 20) => {
  try {
    const response = await api.get("/requirement_recruitments/recruitments/", {
      params: { page, page_size: pageSize },
    });
    return response.data;
  } catch (error) {
    console.error("채용 공고 조회 오류:", error);
    throw error;
  }
};

// 모든 지원자 조회
export const getAllApplicants = async (
  recruitmentId = null,
  page = 1,
  pageSize = 20,
  name = "",
) => {
  try {
    const params = { page, page_size: pageSize };
    if (recruitmentId) {
      params.recruitment_id = recruitmentId;
    }
    if (name) {
      params.name = name;
    }
    const response = await api.get("/requirement_applicants/applicants/", { params });
    return response.data;
  } catch (error) {
    console.error("지원자 조회 오류:", error);
    throw error;
  }
};

// 서류 평가 조회
export const getAllDocumentReviews = async (
  recruitmentId = null,
  page = 1,
  pageSize = 20,
  name = "",
) => {
  try {
    const params = { page, page_size: pageSize };
    if (recruitmentId) {
      params.recruitment_id = recruitmentId;
    }
    if (name) {
      params.name = name;
    }
    const response = await api.get("/requirement_document_review/document_reviews/", { params });
    return response.data;
  } catch (error) {
    console.error("서류 평가 조회 오류:", error);
    throw error;
  }
};

// 1차 면접 조회
export const getAllfirstInterview = async (
  recruitmentId = null,
  page = 1,
  pageSize = 20,
  name = "",
) => {
  try {
    const params = { page, page_size: pageSize };
    if (recruitmentId) {
      params.recruitment_id = recruitmentId;
    }
    if (name) {
      params.name = name;
    }
    const response = await api.get("/requirement_first_interview/first_interviews/", { params });
    return response.data;
  } catch (error) {
    console.error("1차 면접 조회 오류:", error);
    throw error;
  }
};

// 2차 면접 조회
export const getAllsecondInterview = async (
  recruitmentId = null,
  page = 1,
  pageSize = 20,
  name = "",
) => {
  try {
    const params = { page, page_size: pageSize };
    if (recruitmentId) {
      params.recruitment_id = recruitmentId;
    }
    if (name) {
      params.name = name;
    }
    const response = await api.get("/requirement_second_interview/second_interviews/", { params });
    return response.data;
  } catch (error) {
    console.error("2차 면접 조회 오류:", error);
    throw error;
  }
};

// 최종 입사자 조회
export const getAllFinalAcceptances = async (
  recruitmentId = null,
  page = 1,
  pageSize = 20,
  name = "",
) => {
  try {
    const params = { page, page_size: pageSize };
    if (recruitmentId) {
      params.recruitment_id = recruitmentId;
    }
    if (name) {
      params.name = name;
    }
    const response = await api.get("/requirement_final_acceptance/final_acceptances/", { params });
    return response.data;
  } catch (error) {
    console.error("최종 입사자 조회 오류:", error);
    throw error;
  }
};

// 채용 공고 생성 API
export const createRecruitment = async (newRecruitmentData) => {
  try {
    const response = await api.post("/requirement_recruitments/recruitments/", newRecruitmentData);
    return response.data;
  } catch (error) {
    console.error("채용 공고 생성 오류:", error);
    throw error;
  }
};

// 지원자 생성 API
export const createApplicant = async (newApplicantData) => {
  try {
    const response = await api.post("/requirement_applicants/applicants/", newApplicantData);
    return response.data;
  } catch (error) {
    console.error("지원자 생성 오류:", error);
    throw error;
  }
};

// 채용 공고 업데이트 API
export const updateRecruitment = async (recruitmentId, updateData) => {
  try {
    const response = await api.put(
      `/requirement_recruitments/recruitments/${recruitmentId}/`,
      updateData,
    );
    return response.data;
  } catch (error) {
    console.error("채용 공고 수정 오류:", error);
    throw error;
  }
};

// 지원자 업데이트 API
export const updateApplicant = async (applicantId, updateData) => {
  try {
    const response = await api.put(
      `/requirement_applicants/applicants/${applicantId}/`,
      updateData,
    );
    return response.data;
  } catch (error) {
    console.error("지원자 수정 오류:", error);
    throw error;
  }
};

// 서류 평가 업데이트 API
export const updateDocumentReview = async (applicantId, updateData) => {
  try {
    const response = await api.put(
      `/requirement_document_review/document_reviews/${applicantId}/`,
      updateData,
    );
    return response.data;
  } catch (error) {
    console.error("서류 평가 수정 오류:", error);
    throw error;
  }
};

// 1차 면접 업데이트 API
export const updateFirstInterview = async (applicantId, updateData) => {
  try {
    const response = await api.put(
      `/requirement_first_interview/first_interviews/${applicantId}/`,
      updateData,
    );
    return response.data;
  } catch (error) {
    console.error("1차 면접 수정 오류:", error);
    throw error;
  }
};

// 2차 면접 업데이트 API
export const updateSecondInterview = async (applicantId, updateData) => {
  try {
    const response = await api.put(
      `/requirement_second_interview/second_interviews/${applicantId}/`,
      updateData,
    );
    return response.data;
  } catch (error) {
    console.error("2차 면접 수정 오류:", error);
    throw error;
  }
};

// 최종 입사자 업데이트 API
export const updateFinalAcceptance = async (applicantId, updateData) => {
  try {
    const response = await api.put(
      `/requirement_final_acceptance/final_acceptances/${applicantId}/`,
      updateData,
    );
    return response.data;
  } catch (error) {
    console.error("최종 입사자 정보 수정 오류:", error);
    throw error;
  }
};

// 채용 공고 삭제 API
export const deleteRecruitment = async (recruitmentId) => {
  try {
    const response = await api.delete(`/requirement_recruitments/recruitments/${recruitmentId}/`);
    return response.data;
  } catch (error) {
    console.error("채용 공고 삭제 오류:", error);
    throw error;
  }
};

// 지원자 삭제 API
export const deleteApplicant = async (applicantId) => {
  try {
    const response = await api.delete(`/requirement_applicants/applicants/${applicantId}/`);
    return response.data;
  } catch (error) {
    console.error("지원자 삭제 오류:", error);
    throw error;
  }
};

// 서류 평가 삭제 API
export const deleteDocumentReview = async (applicantId) => {
  try {
    const response = await api.delete(
      `/requirement_document_review/document_reviews/${applicantId}/`,
    );
    return response.data;
  } catch (error) {
    console.error("서류 평가 삭제 오류:", error);
    throw error;
  }
};

// 1차 면접 삭제 API
export const deleteFirstInterview = async (applicantId) => {
  try {
    const response = await api.delete(
      `/requirement_first_interview/first_interviews/${applicantId}/`,
    );
    return response.data;
  } catch (error) {
    console.error("1차 면접 삭제 오류:", error);
    throw error;
  }
};

// 2차 면접 삭제 API
export const deleteSecondInterview = async (applicantId) => {
  try {
    const response = await api.delete(
      `/requirement_second_interview/second_interviews/${applicantId}/`,
    );
    return response.data;
  } catch (error) {
    console.error("2차 면접 삭제 오류:", error);
    throw error;
  }
};

// 최종 입사자 삭제 API
export const deleteFinalAcceptance = async (applicantId) => {
  try {
    const response = await api.delete(
      `/requirement_final_acceptance/final_acceptances/${applicantId}/`,
    );
    return response.data;
  } catch (error) {
    console.error("최종 입사자 삭제 오류:", error);
    throw error;
  }
};

// 개인별 가능한 시간대 설정
export const postAvailableTimes = async (scheduleId, availableTimes) => {
  try {
    const response = await api.post(
      `/requirement_schedule_coordination/available-times/${scheduleId}/`,
      availableTimes,
    );
    return response.data;
  } catch (error) {
    console.error("개인별 가능한 시간대 설정 오류:", error);
    throw error;
  }
};

// 면접 일정 추가 및 수정 API
export const updateInterviewSchedule = async (scheduleId, updateData) => {
  try {
    const response = await api.put(
      `/requirement_schedule_coordination/interview_schedules/${scheduleId}/`,
      updateData,
    );
    return response.data;
  } catch (error) {
    console.error("면접 일정 수정 오류:", error);
    throw error;
  }
};

// 면접 일정 초기화 API
export const resetInterviewSchedule = async (scheduleId) => {
  try {
    const response = await api.delete(
      `/requirement_schedule_coordination/interview_schedules/${scheduleId}/`,
    );
    return response.data;
  } catch (error) {
    console.error("면접 일정 초기화 오류:", error);
    throw error;
  }
};

// 이메일 전송 API
export const sendEmailToApplicants = async (subject, message, recipientList) => {
  try {
    const response = await api.post("/requirement_applicants/applicants/mail/", {
      subject,
      message,
      recipient_list: recipientList,
    });
    return response.data;
  } catch (error) {
    console.error("이메일 전송 오류:", error);
    if (error.response?.status === 400) throw new Error("잘못된 요청입니다.");
    if (error.response?.status === 403) throw new Error("권한이 부족합니다.");
    if (error.response?.status === 500) throw new Error("서버 오류가 발생했습니다.");
    throw error;
  }
};

// 문자 전송 API
export const sendTextToApplicants = async (phoneNumber, content) => {
  try {
    const response = await api.post("/requirement_applicants/applicants/text/", {
      phone_number: phoneNumber,
      content,
    });
    return response.data;
  } catch (error) {
    console.error("문자 전송 오류:", error);
    if (error.response?.status === 400) throw new Error("잘못된 요청입니다.");
    if (error.response?.status === 403) throw new Error("권한이 부족합니다.");
    if (error.response?.status === 500) throw new Error("서버 오류가 발생했습니다.");
    throw error;
  }
};
