import styled from "@emotion/styled";

export const TableWrap = styled.section`
  text-align: left;

  height: calc(100vh - 104px);
  width: 100%;
  min-width: 100px;
  max-width: 100%;
  overflow: auto;

  .ag-theme-alpine {
    display: flex;
    flex-direction: column-reverse;
  }

  .ag-root-wrapper.ag-layout-normal {
    border: none;
  }

  .ag-paging-panel {
    order: -1;
    margin-bottom: 4px;
  }

  /* 줄바꿈 허용 */
  .ag-theme-alpine .ag-cell {
    word-wrap: break-word; /* 단어 단위로 줄바꿈 */
  }

  .command_container {
    display: flex;
    justify-content: space-between;
    margin: 0 18px;
    gap: 18px;
    height: 48px;
  }

  .command-controls {
    /* height: 48px; */
    display: flex;
    gap: 8px;
    justify-content: center;
    align-items: center;
  }

  .search-controls {
    display: flex;
    align-items: center;
    flex: 1;
  }

  .pagination-controls {
    display: flex;
    gap: 8px;
    justify-content: center;
    align-items: center;
  }

  .pagination_num {
    width: 30px;
    text-align: center;
  }

  .pagination_select {
    margin-right: 8px;
  }

  .ag-header,
  .ag-advanced-filter-header {
    border-top: var(--ag-borders-critical) var(--ag-border-color);
  }

  .schedule-button {
  }

  .hoverable-cell {
    cursor: pointer; /* 기본 클릭 가능 스타일 */
    transition: color 0.3s ease; /* 부드러운 색상 전환 */
  }

  .hoverable-cell:hover {
    color: #007bff; /* 호버 시 글씨 색상 변경 (예: 파란색) */
  }

  @media screen and (max-width: 992px) {
  }
`;

export const StyledCheckbox = styled.input``;
