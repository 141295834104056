import styled from "@emotion/styled";

export const SidebarContainer = styled.div`
  width: 280px;
  padding: 16px;
  background-color: #f8f8f8;
  border-right: 1px solid #ddd;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  height: calc(100vh - 104px);
  overflow: auto;

  .category_header {
    margin-bottom: 12px;
    font-size: 16px;
    text-align: left;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    .category_header-option {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      gap: 8px;
      letter-spacing: -0.6px;
      font-size: 12px;
    }

    .entire {
      cursor: pointer;
    }

    .entire.active {
      font-weight: bold;
      color: #6a0dad; /* 활성화 상태 색상 */
    }
    .show-closing {
      cursor: pointer;
      color: #666;
    }
    .show-closing.active {
      font-weight: bold;
      color: #333;
    }
  }

  .category-section {
    margin-bottom: 10px;
  }

  .top-category {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
  }

  .category-item {
    display: flex;
    align-items: center;
    padding: 8px 0;
    color: #333;
    font-size: 14px;
    background: none;
    background-color: #f8f8f8;
    border: none;
    border-radius: 4px;
    width: 100%;
    text-align: left;
    cursor: pointer;
    transition: background-color 0.3s ease, filter 0.3s ease;
  }

  .category-item:hover {
    filter: brightness(0.92);
  }

  .category-item svg {
    margin-right: 10px;
  }

  .sub-category-item {
    padding: 8px;
    font-size: 13px;
    color: #666;
    background-color: #f8f8f8;
    cursor: pointer;
    display: flex;
    align-items: flex-start;
    border-radius: 4px;
    transition: background-color 0.3s ease, filter 0.3s ease;
  }

  .sub-category-item.active {
    background-color: rgba(201, 196, 255, 0.7);
    font-weight: 700;
  }

  .sub-category-item:hover {
    filter: brightness(0.92);
  }

  .sub-category-icon {
    margin-right: 8px;
  }

  .label-container {
    display: flex;
    gap: 4px;
    justify-content: space-between;
    width: 100%;
    align-items: center;
  }

  @media screen and (max-width: 992px) {
    width: 100%;
  }
`;
