import axios from "axios";
import Cookies from "js-cookie";
import { logout } from "./auth";

let csrfToken = null; // 전역 변수로 CSRF 토큰을 저장

// Axios 인스턴스 생성
const api = axios.create({
  baseURL: "https://api.nextrunners.co.kr/api/v1/",
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: true, // 쿠키 자동 포함
});

// CSRF 토큰을 받아와 전역 변수에 저장하는 함수
async function initializeCSRFToken() {
  try {
    const response = await api.get("users/csrf/"); // CSRF 토큰 엔드포인트 호출
    csrfToken = response.data.csrfToken; // 응답에서 CSRF 토큰 저장
    console.log("CSRF 토큰 초기화 성공:", csrfToken);
  } catch (error) {
    console.error("CSRF 토큰 초기화 실패:", error);
  }
}

// 요청 인터셉터 추가하여 CSRF 토큰을 자동으로 포함
api.interceptors.request.use((config) => {
  if (csrfToken) {
    config.headers["X-CSRFToken"] = csrfToken; // CSRF 토큰을 헤더에 추가
  } else {
    console.warn("CSRF 토큰이 설정되지 않았습니다. 요청에 추가하지 않습니다.");
  }
  return config;
});

// 쿠키에서 토큰 제거 함수
function clearCookies() {
  Cookies.remove("access_token", { path: "/" }); // `access_token` 제거
  Cookies.remove("refresh_token", { path: "/" }); // `refresh_token` 제거
  localStorage.removeItem("user_name");
  localStorage.removeItem("authority");
  localStorage.removeItem("team_name");
  console.log("Access 및 Refresh 토큰이 쿠키에서 제거되었습니다.");
}

// 로그아웃 처리 함수
async function handleLogout() {
  try {
    // 로그아웃 API 호출
    await logout(); // `auth.js`에 정의된 `logout` 함수 호출
    console.log("로그아웃 API 호출 성공");

    // 쿠키에서 토큰 제거
    clearCookies();
  } catch (error) {
    console.error("로그아웃 처리 중 오류 발생:", error);
  }
}

// 응답 인터셉터 추가하여 401 에러 처리 및 로그아웃
api.interceptors.response.use(
  (response) => response, // 성공 응답은 그대로 반환
  async (error) => {
    const originalRequest = error.config;

    if (error.response?.status === 401) {
      console.warn("401 Unauthorized 에러 발생");

      // 로그아웃 처리 후 로그인 페이지로 리다이렉트
      await handleLogout();
    }

    // 다른 에러는 그대로 전달
    return Promise.reject(error);
  },
);

// 초기화 함수 호출 (앱 시작 시 한 번 실행)
initializeCSRFToken();

export default api;
