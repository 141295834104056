import React, { useState, useEffect, useRef } from "react";
import { useAutocomplete, AutocompleteGetTagProps } from "@mui/base/useAutocomplete";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import { autocompleteClasses } from "@mui/material/Autocomplete";

const Root = styled("div")`
  /* color: rgba(0, 0, 0, 0.85); */
  font-size: 14px;
  width: 100%;
`;

const InputWrapper = styled("div")`
  width: 100%;
  /* border: 1px solid #d9d9d9; */
  background-color: #fff;
  /* border-radius: 4px; */
  padding: 1px;
  display: flex;
  flex-wrap: wrap;
  position: relative;

  &:hover {
    border-color: #40a9ff;
  }

  &.focused {
    border-color: #40a9ff;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  }

  & input {
    color: rgba(0, 0, 0, 0.85);
    height: 30px;
    box-sizing: border-box;
    padding: 4px 6px;
    width: 100%;
    max-width: 30px;
    flex-grow: 1;
    border: 0;
    margin: 0;
    outline: 0;
    text-align: center;
    height: 24px;
    margin: 2px;
    line-height: 22px;
    background-color: #fafafa;
    border: 1px solid #e8e8e8;
    border-radius: 2px;
  }
`;

const Listbox = styled("ul")`
  /* width: 300px; */
  margin: 2px 0 0;
  padding: 0;
  position: absolute;
  top: 0;
  right: 0;
  list-style: none;
  background-color: #fff;
  overflow: auto;
  max-height: 250px;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 9999;

  & li {
    padding: 5px 12px;
    display: flex;

    & span {
      flex-grow: 1;
    }

    & svg {
      color: transparent;
    }
  }

  & li[aria-selected="true"] {
    background-color: #fafafa;
    font-weight: 600;

    & svg {
      color: #1890ff;
    }
  }

  & li.${autocompleteClasses.focused} {
    background-color: #e6f7ff;
    cursor: pointer;

    & svg {
      color: currentColor;
    }
  }
`;

const StyledTag = styled("div")`
  display: flex;
  align-items: center;
  height: 24px;
  margin: 2px;
  line-height: 22px;
  background-color: #fafafa;
  border: 1px solid #e8e8e8;
  border-radius: 2px;
  box-sizing: content-box;
  padding: 0 4px 0 10px;
  outline: 0;
  overflow: hidden;

  &:focus {
    border-color: #40a9ff;
    background-color: #e6f7ff;
  }

  & span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  & svg {
    font-size: 12px;
    cursor: pointer;
    color: #000; /* CloseIcon 색상 설정 */
  }
`;

function MultiSelector(props) {
  console.log("props", props);
  const { value: initialValue, onValueChanged, stopEditing } = props;
  const [selectedValues, setSelectedValues] = useState(initialValue || []);
  const ref = useRef(null);

  // initialValue가 변경될 때 selectedValues를 업데이트하는 useEffect 추가
  useEffect(() => {
    setSelectedValues(initialValue || []);
  }, [initialValue]);

  useEffect(() => {
    if (ref.current) {
      ref.current.focus();
    }
  }, []);

  const {
    getRootProps,
    getInputProps,
    getTagProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
    focused,
    setAnchorEl,
  } = useAutocomplete({
    id: "customized-hook-demo",
    value: selectedValues,
    multiple: true,
    options: props.options,
    getOptionLabel: (option) => option,
    isOptionEqualToValue: (option, value) => option === value,
    onChange: (_, newValue) => {
      console.log("Selected values changed:", newValue);
      setSelectedValues(newValue); // 로컬 상태 업데이트
      if (onValueChanged) {
        onValueChanged({ newValue }); // 변경된 값을 부모로 전달
      }
    },
  });

  const handleDelete = (index) => {
    const newValues = selectedValues.filter((_, i) => i !== index);
    setSelectedValues(newValues);
    if (onValueChanged) {
      onValueChanged({ newValue: newValues });
    }
  };

  const handleSave = () => {
    console.log("handleSave triggered with values:", selectedValues);

    if (stopEditing) {
      stopEditing(); // Ag-Grid 셀 편집 종료
    }

    if (onValueChanged) {
      // 데이터 전달 시 배열 형식 확인
      onValueChanged({
        newValue: selectedValues.map((value) => ({ name: value })), // name 속성으로 매핑
        rowId: props.data?.id, // 현재 행의 ID
        rowData: props.data, // 전체 행 데이터
      });
    }
  };

  return (
    <Root>
      <div {...getRootProps()}>
        <InputWrapper ref={setAnchorEl} className={focused ? "focused" : ""}>
          {selectedValues.map((option, index) => {
            const { key, ...tagProps } = getTagProps({ index });

            return (
              <StyledTag key={key} {...tagProps}>
                <span>{option}</span>
                <CloseIcon onClick={() => handleDelete(index)} />
              </StyledTag>
            );
          })}
          <input
            {...getInputProps()}
            placeholder="+"
            onBlur={handleSave} // 포커스가 사라질 때 저장
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleSave(); // Enter 키로 저장
              }
            }}
          />
        </InputWrapper>
      </div>
      {groupedOptions.length > 0 ? (
        <Listbox {...getListboxProps()} ref={ref}>
          {groupedOptions.map((option, index) => {
            const { key, ...optionProps } = getOptionProps({ option, index });
            return (
              <li key={key} {...optionProps}>
                <span>{option}</span>
                <CheckIcon fontSize="small" />
              </li>
            );
          })}
        </Listbox>
      ) : null}
    </Root>
  );
}

export default MultiSelector;
