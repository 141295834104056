import React, { useState } from "react";
import { SidebarContainer } from "./Sidebar.styled";
import { useNavigate, useLocation } from "react-router-dom";
import useDateFormatter from "../../../hooks/useDateFormatter";

function Sidebar({
  categories,
  selectedCategory,
  setSelectedCategory,
  openTopCategories,
  setOpenTopCategories,
  activeTab,
  resetCategory,
}) {
  const { formatDate } = useDateFormatter();
  const navigate = useNavigate();
  const location = useLocation();

  const [showClosed, setShowClosed] = useState(false); // 마감 포함 여부 상태

  const handleTopCategoryClick = (category) => {
    setOpenTopCategories((prev) =>
      prev.includes(category) ? prev.filter((c) => c !== category) : [...prev, category],
    );
  };

  const handleSubCategoryClick = (topCategory, subCategory) => {
    setSelectedCategory(topCategory, subCategory.id); // topCategory와 하위 항목 ID를 전달
  };

  const handleViewAllClick = () => {
    resetCategory(); // 부모에서 상태 초기화
    const params = new URLSearchParams(location.search);
    const tab = params.get("tab");
    const newParams = new URLSearchParams();
    if (tab) newParams.set("tab", tab);

    navigate(`?${newParams.toString()}`, { replace: true });
  };

  const toggleShowClosed = () => {
    setShowClosed((prev) => !prev); // 마감 포함 상태 토글
  };

  // console.log(selectedCategory);

  return (
    <SidebarContainer>
      <div className="category_header">
        <h3>채용 공고</h3>
        {activeTab !== "jobPostings" && (
          <div className="category_header-option">
            <span
              className={`entire ${selectedCategory === null ? "active" : ""}`}
              onClick={handleViewAllClick}
            >
              전체 보기
            </span>
            <span
              className={`show-closing ${showClosed ? "active" : ""}`}
              onClick={toggleShowClosed}
            >
              마감 포함
            </span>
          </div>
        )}
      </div>
      {Object.keys(categories)
        .filter((topCategory) => {
          // 해당 topCategory에 표시 가능한 subCategory가 있는지 확인
          const visibleSubCategories = categories[topCategory].filter(
            (subCategory) => showClosed || !subCategory.is_closed,
          );
          return visibleSubCategories.length > 0; // 표시 가능한 subCategory가 있을 때만 topCategory를 표시
        })
        .map((topCategory) => (
          <div key={topCategory} className="category-section">
            <div className="top-category">
              <button
                onClick={() => handleTopCategoryClick(topCategory)}
                className={`category-item ${
                  openTopCategories.includes(topCategory) ? "active" : ""
                }`}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  style={{
                    transform: openTopCategories.includes(topCategory)
                      ? "rotate(0deg)"
                      : "rotate(-90deg)",
                    transition: "transform 0.3s",
                  }}
                  width="12"
                  height="12"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path d="M6 9l6 6 6-6" />
                </svg>
                {topCategory}
              </button>
            </div>

            {activeTab !== "jobPostings" &&
              openTopCategories.includes(topCategory) &&
              categories[topCategory]
                .filter((subCategory) => showClosed || !subCategory.is_closed) // 필터 조건 추가
                .map((subCategory) => (
                  <div
                    key={subCategory.id}
                    className={`sub-category-item ${
                      String(selectedCategory) === String(subCategory.id) ? "active" : ""
                    }`}
                    onClick={() => handleSubCategoryClick(topCategory, subCategory)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="12"
                      height="12"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="sub-category-icon"
                    >
                      <rect x="3" y="3" width="18" height="18" rx="2" ry="2" />
                      <path d="M9 3v18" />
                    </svg>
                    <div className="label-container">
                      <p>
                        {formatDate(subCategory.registration_date)} <br />~{" "}
                        {subCategory.deadline_date}
                      </p>
                      <p
                        style={{
                          color: subCategory.is_closed ? "#666" : "#8148ab", // 조건에 따른 색상 적용
                        }}
                      >
                        ({subCategory.is_closed ? "마감" : "채용중"})
                      </p>
                    </div>
                  </div>
                ))}
          </div>
        ))}
    </SidebarContainer>
  );
}

export default Sidebar;
