import styled from "@emotion/styled";

export const HeaderWrap = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
  height: 56px;
  background-color: #f8f8f8;

  .userWrap {
    display: flex;
    align-items: center;
    gap: 16px;
  }

  .userBox {
  }

  .logoutBox {
    cursor: pointer;
  }

  .loginBox {
    cursor: pointer;
  }
  @media screen and (max-width: 992px) {
  }
`;
